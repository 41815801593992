
import React, {useState, useEffect, Fragment} from "react"
import { Col, Row, Pagination} from 'antd'
import { useTranslation } from "react-i18next"
import classes from "./styles.module.css"
import { useUserFavoriteAddOrRemovePoint } from '../../hooks/useUser'
import { useAuth } from "../../utils/auth"

import SearchItemComponent from '../../components/Box/SearchItemComponent'
import { useDispatch } from 'react-redux'
import { SearchPageNumberRedux, SearchPageSizeRedux }  from '../../redux/mapPage/search'


interface Props{
  lg: number
  xs: number
  array: any
  total: number
  setPoisesMarkerExploration: any
  isAuth?: boolean
  isAddOrDelete?: boolean
  onClickAddRemove: (idPois: string) => void,
  paginationNumber: number
  paginationSize: number
  imageSize?: string
}

const ViewPointsComponent: React.FC<Props> = ({ lg, xs, array, total, setPoisesMarkerExploration, isAuth, onClickAddRemove, isAddOrDelete, paginationNumber, paginationSize, imageSize  }:Props ) => {

  const { t } = useTranslation()
  const auth = useAuth()
  const [Poises, setPoises] = useState<any[]>(array);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(8);


  const [pointId, setPointId] = useState<string>('')
  const [addDel, setAddDel] = useState<number>(0)

  const { refetch } = useUserFavoriteAddOrRemovePoint(auth.userData.id, pointId, addDel)

  const dispatch = useDispatch()

  useEffect(() => {

    setPoises(array)
    setPoisesMarkerExploration(currentPage * pageSize > array.length ? array.slice((currentPage - 1) * pageSize) : array.slice((currentPage - 1) * pageSize, currentPage * pageSize))

  }, [array])
  

  useEffect(() => {

    if (paginationNumber !== 0 || paginationSize != 9)
    setPoises(paginationNumber * paginationSize > array.length ? array.slice((paginationNumber - 1) * paginationSize) : array.slice((paginationNumber - 1) * paginationSize, paginationNumber * paginationSize))
      
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      setPoisesMarkerExploration(paginationNumber * paginationSize > array.length ? array.slice((paginationNumber - 1) * paginationSize) : array.slice((paginationNumber - 1) * paginationSize, paginationNumber * paginationSize)) 
  
  }, [paginationNumber, paginationSize])


  const onChange = (page: number, pageSize: number) => {


    dispatch(SearchPageNumberRedux(page))
    dispatch(SearchPageSizeRedux(pageSize))

    setCurrentPage(page)
    setPageSize(pageSize)
    setPoises(page * pageSize > array.length ? array.slice((page - 1) * pageSize) : array.slice((page - 1) * pageSize, page * pageSize))
    window.scrollTo({ top: 300, left: 0, behavior: 'smooth' })
    setPoisesMarkerExploration(page * pageSize > array.length ? array.slice((page - 1) * pageSize) : array.slice((page - 1) * pageSize, page * pageSize))
  }


  const onClickAddOrRemove = (text: string, idPois: string, numberAddDel: number) => {

    setPointId(idPois)
    setAddDel(numberAddDel)
  }

  useEffect(() => {
    if (pointId !== '')
        refetch()
  }, [pointId])


  return (
    <>
      <Row justify="center">
        <Col xs={{span: xs}} sm={{span: xs}} md={{span: lg}} lg={{span: lg}}>

          {Poises.slice(0, pageSize).map((pois: any, index: any) => {

          let textShort = `${pois.text ? pois.text.substring(0, imageSize == 'small' ? 100 : 120) : ''}`
          textShort = textShort.slice(0, textShort.lastIndexOf("&"))
            
            return (
              <Fragment key={pois.id}>
                <SearchItemComponent 
                  id={pois.id}
                  type={ pois.type}
                  image = {pois.image !== '' ? pois.image : '../img/defaultPoisImage.jpg'}
                  title={pois.title}
                  category_id={pois.category_id}
                  text={textShort}
                  municipality={pois.municipality}
                  categoryName={pois.categoryName}
                  timePeriods = {pois.timePeriods}
                  isAuth={isAuth}
                  isAddOrDelete={isAddOrDelete}
                  onClickAddOrRemovePois={(text, idPois, AddOrDelete) => onClickAddOrRemove(text, idPois, AddOrDelete)}
                  onClickAddRemovePois={(idPois) => onClickAddRemove(idPois)}


                  rate={pois.rate}
                  // category={pois.category}
                  // imageSize='small'
                  codesPerRoute={pois.typesOfRoute}

   
                />
              </Fragment>
            )
          })}

        </Col>
      </Row>
      
      <Row justify="center">
        <Col span={23}>
          <Pagination
            onChange={onChange}
            className={classes.pagination}
            total={total}
            showSizeChanger ={true}
            showTotal={(total, range) => t('Αποτελέσματα') + ' ' + `${range[0]} - ${range[1]}` + ' ' + t('από') + ' ' + `${total}`}
            defaultPageSize={paginationSize}
            pageSizeOptions={[9, 21, 60, 100]}
            current={paginationNumber !== 0 ? paginationNumber : 1  }
            // defaultPageSize={8}
            // defaultCurrent={1}
            // hideOnSinglePage={true}
            // responsive={true}
          />
        </Col>
      </Row>
    </>
  )
}

export default ViewPointsComponent