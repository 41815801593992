import React from 'react'
import { Row, Col } from 'antd'
import classes from "./css/About.module.css"
import { useTranslation } from "react-i18next"


const About: React.FC = () => {

  const { t } = useTranslation()

  return (
    <>
      <div className={classes.container}>

        <Row justify="center">
          <Col xs={24} sm={24} md={24} lg={24}>
            <div className={classes.title}>
              {t('Τι είναι το Chania Routes;')}
            </div>
          </Col>
          <Col lg={4}> </Col>
        </Row>




        <Row justify="center">
          <Col xs={{ span: 22 }} lg={{ span: 18 }} >
            <div className={classes.subTitle}>
              {t('AboutText') }
            </div><br/><br/>
            <div className={classes.subTitle2}>
              {t('AboutText2') }
            </div>
          </Col>

          <Col xs={{ span: 22 }} lg={{ span: 6 }} >
            <div className={classes.subTitleImage}>
              <img className={classes.image} alt="Chania image 1" src="./img/aigida.png" />
            </div>
          </Col>
        </Row>

      </div>
    </>
  )
}

export default About
