import React, {useState, useEffect} from "react"
import { Checkbox, Col, Row } from 'antd'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'
import Spinner from '../PageComponents/Spinner'
import { useTranslation } from "react-i18next"
import classes from "./styles.module.css"
import { NodeIndexOutlined } from "@ant-design/icons"
import EventsSvg from '../../img/svg/events'


import {RoutesSearchBoxData} from './routes'
import {EventsData} from './events'
import {MunicipalitiesSearchBoxData} from './municipalities'
import {useLocation} from "react-router-dom"


// Redux
import { useDispatch } from 'react-redux'
import { RoutesSearchRedux }  from '../../redux/mapPage/search'
import { useExploration } from '../../hooks/useExploration'
import i18n from '../../translation'


import CheckBoxComponent from '../../pages/Search/checkBoxGroup'
import RoutesCheckBoxComponent from '../../pages/Search/CheckBoxes/routes'
import EventsCheckBoxComponent from '../../pages/Search/CheckBoxes/events'



interface ListData {
  id: string
  item: string
  value: string
}

interface Props{
  checkedId?: any[]
}

const CheckBoxRouteComponent: React.FC<Props> = ({ ...props } ) => {

  const { t } = useTranslation()

  const dispatch = useDispatch()
  const location = useLocation()
  const { isLoading, arrayExplorationSearchData, poisCategories, refetch } = useExploration()

  const [checkedId] = useState<number | 21>(location?.state?.typeId)

  const onChange = (list: CheckboxValueType[]) => {
    console.log('list', list)
    dispatch(RoutesSearchRedux(list))
  }
  

  const [historyName, setHistoryName] = useState<string>('');
  const [historySubCategories, setHistorySubCategories] = useState<any[]>([]);

  const [environmentName, setEnvironmentName] = useState<string>('');
  const [environmentSubCategories, setEnvironmentSubCategories] = useState<any[]>([]);


  useEffect(() => {
    if (poisCategories[0] !== undefined) {

      setHistoryName(poisCategories[0].name)
      setHistorySubCategories( poisCategories[0].subCategories )

      setEnvironmentName(poisCategories[1].name)
      setEnvironmentSubCategories(poisCategories[1].subCategories)

    }
  }, [poisCategories[0]])


  useEffect(() => {
    refetch()
    
  }, [i18n.language])



  if (isLoading)
  return (<Spinner />)

  return (
    <>
      <Checkbox.Group
        style={{ width: '100%' }}
        onChange={onChange}
        defaultValue={props.checkedId && props.checkedId[0]}>
        <Row>

          <RoutesCheckBoxComponent
            checkedId={checkedId}
            header={t("Διαδρομές") as string}
            array={RoutesSearchBoxData}
            icon={<NodeIndexOutlined style={{ color: "#331410", fontSize: '26px' }} />}
            type={'route'}
          />

          <div className={classes.line}></div>
          <EventsCheckBoxComponent
            checkedId={checkedId}
            header={t("Εκδηλώσεις") as string}
            array={EventsData}
            icon={<EventsSvg width={32} height={32} fill="#331410"/>}
            type={'events'}
          />

        </Row>
      </Checkbox.Group>
    </>
  )
}

export default CheckBoxRouteComponent