import React from 'react'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

export const SampleNextArrow = (props: any) => {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        color: '#63584F',
        fontSize: '26px',
        fontWeight: 'bold',
        marginRight: '60px',
        top: "50%",
        width: "42px",
        height: "42px",
        borderRadius: '50%',
        backgroundColor: 'white',
        border: '1px solid rgb(186 197 201)'
      }}
      onClick={onClick}
    >
      <span style={{marginLeft: '10px', position: 'relative', top: '8px'}}><RightOutlined /></span> 
    </div>
  )
}


export const SamplePrevArrow = (props: any) => {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        color: '#63584F',
        fontSize: '26px',
        fontWeight: 'bold',
        top: "50%",
        marginLeft: "60px",
        width: "42px",
        height: "42px",
        borderRadius: '50%',
        backgroundColor: 'white',
        zIndex: '1',
        border: '1px solid rgb(186 197 201)'
      }}
      onClick={onClick}
    >
      <span style={{marginLeft: '6px', position: 'relative', top: '8px'}}><LeftOutlined /></span>  
    </div>
  )
}