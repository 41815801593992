import React, { useState, useContext, createContext } from "react"

interface UserData {
  id: string
  name: string | null
  email: string
}

const useValue = () => {

  const [userData, setUserData] = useState <UserData>({id: '', name: null, email: ''})

  const login = (id: string, userName: any, userEmail: string) => {
    setUserData(prevData => ({...prevData, id:id, name:userName, email:userEmail}))
  }

  const logout = () => {
    setUserData(prevData => ({...prevData, id:'', name:null, email:''}))
  }

  return {
    userData,
    login,
    logout
  }
}

const AuthContext = createContext({} as ReturnType<typeof useValue>)

export const AuthProvider = ({ children }:any) => {

  return (
    <AuthContext.Provider value={useValue()}>
      {children}
    </AuthContext.Provider>
  )
}


export const useAuth = () => {
  return useContext(AuthContext)
}