import React from "react"
import { Row, Col, Button } from 'antd'
import classes from './styles.module.css'
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

interface Props{
  title: string
  subTitle: string
  color?: string
}

const TitleAndSubtitleAndBtn: React.FC<Props> = ({ title, subTitle, color = '#897563' }: Props) => {

  const { t } = useTranslation()

  const navigate = useNavigate()

  return (
    <div className={classes.container}>
      <div className={classes.container2}>
      <Row justify="center">
          <Col span={24}>
          <span className={classes.divBtnBack}>
            <Button size='large' className={classes.BackBtn} onClick={() => navigate(-1)}>{t('Επιστροφή')}</Button>
          
            <span className={classes.title} style={{color: color}}>{ title }</span>
          </span>
          {/* <div className={classes.subTitle}>
            { subTitle }
          </div> */}
       
       
         
        </Col>
        </Row>
      </div>
    </div>
  )
}

export default TitleAndSubtitleAndBtn