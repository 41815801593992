import { useState } from "react"
import { useQuery } from '@tanstack/react-query'
import { axiosRoutes } from "../services/routes"

// Redux
import { useDispatch } from 'react-redux'
import { SetRoutesArray, SetRoutesArray2, SetPoisesArrayForSearch } from '../redux/mapPage/routesArray'


export const useRoute = (id: string) => {

  const [RouteData, setRouteData] = useState({})
  const dispatch = useDispatch()
  let MyIdPoisArray: any = []
  let MyIdPoisArray2: any = []
  
  const { isLoading, isFetching, error, refetch } = useQuery(['route', id], () => axiosRoutes.getRoute(id), {

    onSuccess: (data) => {

      data.pois.map((poi: any) => {

        MyIdPoisArray.push(poi.id)
        MyIdPoisArray2.push(poi.extras._point_codes_per_route.value)
      })

      dispatch(SetRoutesArray(MyIdPoisArray))
      dispatch(SetRoutesArray2(MyIdPoisArray2))
      

       let route = {
          id: data.id,
          //  description: data.extras._super_short_description.value,
          description: data.description,
          image: data.main_image,
          municipality: data.area_path[0][0],
          title: data.name,
          lat: data.pois[0].latitude,
          lng: data.pois[0].longitude,
          type: data.extras._route_type.value[0],
          pois: data.pois,
          route_coordinates: data.route_coordinates,
          typeRoute: data.extras._route_code['value'],
          colorRoute: data.extras._colour['value'],
          video: data.files.length !== 0 ? data.files['8'][0]['url'] : ""
        }

      setRouteData(route)
    },

    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { isLoading, isFetching, error, RouteData, refetch }
}

export const useRoutes = () => {
  
  const [RoutesData, setRoutesData] = useState()
  const dispatch = useDispatch()
  const arrayPoisForSearch: any= []

  const { isLoading, isFetching, error, refetch: refetchs } = useQuery(['routes'], () => axiosRoutes.getRoutes(), {

    onSuccess: (data) => {
      
      data[1].map((array: any) => {

        array.array.map((poi: any) => { 
          poi.pois.map((poi: any) => { 
            arrayPoisForSearch.push({
              value: poi.name
            })
          })
        })
      })

      console.log(arrayPoisForSearch)

      dispatch(SetPoisesArrayForSearch(arrayPoisForSearch))
      
      setRoutesData(data[0].sort((a: any, b: any) => (a.pois_count > b.pois_count) ? 1 : -1))

    },

    onError: (error:any) => {
      alert(error.message)
    }
  })

  // arrayPoisForSearch

 

  return { isLoading, isFetching, error, RoutesData, refetchs }
}


export const useGetArrayPoisFromRoute = (id: string) => {

  const [RouteData, setRouteData] = useState({})
  const dispatch = useDispatch()

  let MyIdPoisArray: any = []
  let MyIdPoisArray2: any = []


  const { isLoading, isFetching , error, refetch } = useQuery(['useGetArrayPoisFromRoute', id], () => axiosRoutes.getArrayPoisFromRoute(id), {

    onSuccess: (data) => {

      data[0].pois.map((poi: any) => {
        MyIdPoisArray.push(poi.id)
        MyIdPoisArray2.push(poi.extras._point_codes_per_route.value)

      })

      dispatch(SetRoutesArray(MyIdPoisArray))
      dispatch(SetRoutesArray2(MyIdPoisArray2))

    },

    onError: (error:any) => {
      alert(error.message)
    }
  })

  return { isLoading, isFetching, RouteData }
}