
import React from "react"
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'
import Icon from '@ant-design/icons'

    
const ArrowSvgFill = () => {
    return (
        <svg width="17" height="18" viewBox="0 0 15 9" fill="none" xmlns="https://www.w3.org/2000/svg">
            <path d="M1.99902 1.01367L7.81885 7.00487L13.6387 1.01367" stroke="#63584F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

const ArrowSvg = (props: Partial<CustomIconComponentProps>) => (<Icon component={ArrowSvgFill} {...props} />);

export {
    ArrowSvg
} 