
import React, {useState, useEffect} from "react"
import { Row, Col, Button, Spin } from 'antd'
import classes from './styles.module.css'
import { useTranslation } from "react-i18next"
import i18n from '../../translation'
import { useRoute } from '../../hooks/useRoute'
import { useParams, useNavigate } from 'react-router-dom'
import ReactPlayer from 'react-player'


import HelmetComponent from '../../components/Helmet'
import TitleAndSubtitleAndBtnAndTypeRoute from '../../components/PageComponents/TitleAndSubtitleAndBtnAndTypeRoute'
import ViewPointsComponent from './Points/viewPoints'
import LiefletComponent from './Lieflet'

import RouteSvg from '../../img/svg/route'
import MapSvg from '../../img/svg/map'
import {ChangeMunicipality} from '../../components/Box/ChangeMunicipality'
import Events from '../Home/components/Events'

import type { RootState } from '../../redux/store'
import { useSelector } from 'react-redux'


const Routes: React.FC = () => {

  const { id } = useParams()
  const { isLoading, RouteData, refetch } = useRoute(id||'')
  const navigate = useNavigate();


  const { t } = useTranslation()

  const [route, setRoute] = useState<any>({})
  const [polyline, setPolyline] = useState<[number, number][][]>([])
  const [routePoises, setRoutePoises] = useState<[number, number][][]>([])
  const [showMap, setShowMap] = useState<boolean>(false)

  const [latitude, setLatitude] = useState<string>('')
  const [longitude, setLongitude] = useState<string>('')
  const [setPoisesMarkerExploration, PoisesMarkerExploration] = useState<any[]>([])
  
  const ReduxPageNumberExploration: number = useSelector((state: RootState) => state.Categories.ExplorationPageNumberRedux)
  // check if Mobile
  const [width, setWidth] = useState<number>(window.innerWidth);
  

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  

  useEffect(() => {
    if (RouteData) 
      setRoute(RouteData)

  }, [RouteData])


  useEffect(() => {

    let polyline: any[] = []
    let addPois: any[] = []
    let RoutePoises: any[] = []

    if ( Object.keys(route).length !== 0 ) {

        route.route_coordinates.forEach((popyl: any, j: any, row: any): void => {
          if (j + 1 === row.length) {
            addPois.push({ id: route.pois.length + 1, latitude: parseFloat(popyl.latitude), longitude: parseFloat(popyl.longitude), name: 'Finish' })
          }
          polyline.push([parseFloat(popyl.latitude), parseFloat(popyl.longitude)])
        })

      route.pois.forEach((poi: any): void => {

          RoutePoises.push({
            main_id: route.id,
            id: poi.id,
            latitude: parseFloat(poi.latitude),
            longitude: parseFloat(poi.longitude),
            image: poi.main_image,
            title: poi.name,
            text: poi.portal_main_info,
            category: poi.categories[0].name,
            rating: poi.rating !== null ? poi.rating : 0,
            municipality: poi.extras._municipalityName.value,
            typesOfRoute: poi.extras._point_codes_per_route["value"],
            video: route.video
          })
        })

      setPolyline(polyline)
      setRoutePoises(RoutePoises)
      setLatitude(route.lat)
      setLongitude(route.lng)

    }

  },[ route ])


  
  useEffect(() => {
    refetch()
  }, [i18n.language])


  
  if (isLoading || routePoises.length < 1 ) {
    return (
      <Row justify="center" >
        <Col  xs={24} sm={24} md={24} lg={24}>
          <div className={classes.loadingSpinner}> <Spin size="large" /> {t('Παρακαλώ περιμένετε')} ... </div>
        </Col>
      </Row>
    )
  }


  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  
  const isMobile = width <= 768;


  return (
    <>
      
      <HelmetComponent
        title={route.title}
        descrition={route.title+', ' + ChangeMunicipality(t(route.municipality))}
        keywords='Εξερεύνηση'
      />
     
      <TitleAndSubtitleAndBtnAndTypeRoute title={route.title} subTitle={t(route.name)} typeOfRoute={route.typeRoute} backGroundColor={ route.colorRoute } />
      
      
      <div className={classes.container}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} >
            <div className={classes.titleDescription}>{ t('Περιγραφή')}</div>
            <div className={classes.text} dangerouslySetInnerHTML={{ __html: route.description }} ></div>
          </Col>
        </Row>
      </div>


      {showMap &&
        <>  
        <div className={classes.container}>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} >
              <div className={[classes.btn, classes.btnPadding].join(" ")}>
                <Button type="primary" icon={<MapSvg width={20} height={20} fill="#fff" />} size='large' className={classes.btnMap} onClick={() => setShowMap(!showMap)}>&nbsp;&nbsp;  {!showMap ? t('Εμφάνιση Χάρτη') : t('Aπόκρυψη Χάρτη')} </Button>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <LiefletComponent
                latitude={latitude}
                longitude={longitude}
                pois={routePoises}
                polyline={polyline}
                iconImg={<RouteSvg width={25} height={25} fill="#275674" />}
                colorRoute={route.colorRoute}
              />
            </Col>
          </Row>
        </div>
        </>
      } 
      <div className={classes.container}>
        <div className={classes.checkBoxesContainerPoint}>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              {!showMap &&
                <div className={classes.btn}><Button type="primary" icon={<MapSvg width={20} height={20} fill="#fff" />} size='large' className={classes.btnMap} onClick={() => setShowMap(!showMap)}>&nbsp;&nbsp;  {!showMap ? t('Εμφάνιση Χάρτη') : t('Aπόκρυψη Χάρτη')} </Button></div>
              }
            </Col>
          </Row>
        </div>
      </div>




      

      {/* {route.video !== '' && 
        

      <div className={classes.container}>
        
          <Row>
          <Col xs={24} sm={24} md={24} lg={24} >
            <div className={classes.VideoDescription}>{ t('Βιντεο 360 της διαδρομής')}</div>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={24}>
            <div className={classes.containerVideo}>

              <ReactPlayer
                width={isMobile ? '370px' : '800px'}
                height={isMobile ? '240px' : '420px'}
                url={route.video}
              
              />
            </div>
          </Col>
        </Row>
      </div>
    } */}
      <div className={classes.container2}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24}>
            <div className={classes.titleDescription2}>{t('Σημεία της Διαδρομής')}</div>
          </Col>
        </Row>
        <div className={classes.container3}>
          <ViewPointsComponent
            lg={24}
            xs={23}
            paginationNumber={ReduxPageNumberExploration}
            array={routePoises}
            total={routePoises.length}
            isAddOrDelete={true}
            setPoisesMarkerExploration={PoisesMarkerExploration}
            onClickAddRemove={() => ''} />
        </div>
      </div>

      <Events color='grey'/>
      
    </>
  )
}

export default Routes
