import React, {useState} from "react"
import { Col, Row } from 'antd'
import classes from "./styles.module.css"

import s from "../../img/svg/pointers/markerS.svg"
import a from "../../img/svg/pointers/markerA.svg"
import c from "../../img/svg/pointers/markerC.svg"
import f from "../../img/svg/pointers/markerF.svg"
import h from "../../img/svg/pointers/markerH.svg"


// LEAFLET
import { Icon } from 'leaflet'
import { ZoomControl, ScaleControl, MapContainer, TileLayer, Marker, Popup, Polyline, Tooltip} from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import TypesOfRoute from '../../components/TypesOfRouteForLieflet'


// const icon = new Icon({
//   iconUrl: SvgPointer[0], 
//   popupAnchor: [0, -20],
//   iconSize: [40, 40]
// });

interface Props{
  polyline: any
  pois: any
  iconImg: any
  latitude: string
  longitude: string
  colorRoute: string
}

const LiefletComponent: React.FC<Props> = ({ polyline, pois, iconImg, latitude, longitude, colorRoute='green' }:Props ) => {

  const [map, setMap] = useState(null);

  const MarkersPois = () => {
    return <>
      {
        pois.map((po: any, i: number, row: any) => {

          let codePoi = ''
          let iconSVG: any
          let gramma: string
          let textShort = `${po.text.substring(0, 120)}`
          textShort = textShort.slice(0, textShort.lastIndexOf("&"))

          switch (colorRoute) {
            case '#23CEFD':
              iconSVG = s; gramma = 'S'; break;
            case '#97CC27':
              iconSVG = a; gramma = 'A'; break;
            case '#97473F':
              iconSVG = c; gramma = 'C'; break;
            case '#807357':
              iconSVG = f; gramma = 'F'; break;
            default:
              iconSVG = h; gramma = 'H'; break;

          }

          const icon = new Icon({
            iconUrl: iconSVG,
            popupAnchor: [0, -19],
            iconSize: [54,54]
          });


          if(po.typesOfRoute.length == 3)
            codePoi = po.typesOfRoute.substring(0, 3)
          
          else if(po.typesOfRoute.length == 7) {
            codePoi = po.typesOfRoute.split(',')
            codePoi = codePoi[0].substring(0, 1) == gramma ? codePoi[0] : codePoi[1]
          }
            
          else {
            codePoi = po.typesOfRoute.split(',')
            codePoi = codePoi[0].substring(0, 1) == gramma ? codePoi[0] : codePoi[1].substring(0, 1) == gramma ? codePoi[1] : codePoi[2]
          }

          return (
            <Marker
              key={po.id}
              position={[parseFloat(po.latitude)+0.0001, parseFloat(po.longitude)]}
              icon={icon}
            >
              <Popup>
                <div className={classes.MarkerGpsInfoWindow}>
                  <img src={po.image !== '' ? po.image : '../img/defaultPoisImage.jpg'} alt={po.title} />
                  <div className={classes.gpsInfoWindow}>
                    <div className={classes.pointMapTitle}>{po.title}</div>
                  </div>
                  <div className={classes.PointCenter}>
                    <p className={classes.MarkerPointCenterCenterP}>{textShort}...</p>
                  </div>
                  <TypesOfRoute codesPerRoute={po.typesOfRoute} />  
                </div>
              </Popup>
              <span>
                <Tooltip direction="top" offset={[0, 3]} opacity={1} permanent >{codePoi}</Tooltip>
              </span>
            </Marker>
          )
        }
        )}
    </>
  }


  return (
    <>
      <Row justify="center">
        <Col span={23}>

          <MapContainer
            key={JSON.stringify([35.512632579872985, 24.02207924693865])} // To center every time
            // center={[parseFloat(latitude), parseFloat(longitude)]}
            center={[35.512632579872985, 24.02207924693865]}
            zoom={14}
            tap={false}
            style={{ height: '573px', width: '100%', zIndex: '0' }}
            zoomControl={false}
            scrollWheelZoom={false}
            minZoom={12}
            maxZoom={ (colorRoute == '#23CEFD' || colorRoute == '#97CC27' || colorRoute == '#97473F' ) ? 15 : colorRoute == '#807357' ? 16 : 17}
            
          >
            <ZoomControl position='bottomright' />
            <ScaleControl position="bottomright" metric={true} imperial={false} />
            <MarkersPois />
            <TileLayer url="https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png" />

            <Polyline
              key={1}
              color={colorRoute}
              weight={6}
              positions={polyline}
            />
          </MapContainer>

        </Col>
      </Row>
    </>
  )
}

export default LiefletComponent