import React, { useState, useEffect } from "react"
import { Col, Row, Radio, DatePicker } from 'antd'
import dayjs from 'dayjs';
import type  { Dayjs } from 'dayjs'
import type { RadioChangeEvent } from 'antd'
import { useTranslation } from "react-i18next"
import classes from "./styles.module.css"
import { DatesData } from './dates'
import 'dayjs/locale/el';
import locale from 'antd/es/date-picker/locale/el_GR'


import CalendarSvg from '../../img/svg/calendar'
import { ClockCircleOutlined } from '@ant-design/icons'
 

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { DatesRedux, EventsDateStartRedux, EventsDateEndRedux } from '../../redux/mapPage/event'
import type { RootState } from '../../redux/store'


import type { RangePickerProps } from 'antd/es/date-picker';


interface Props{
  checkedId: any[],
  setDateRangeStart: any
  setDateRangeEnd:any
}

const { RangePicker } = DatePicker;

const CheckBoxDatesComponent: React.FC<Props> = ({ checkedId, setDateRangeStart, setDateRangeEnd}) => {

  const { t } = useTranslation()

  const now = dayjs().format('DD/MM/YYYY')
  const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY']
  const [selectDate, setSelectDate] = useState<boolean>(false)
  const [rangePickerOpen, setRangePickerOpen] = useState<boolean>(false)

  
  const dispatch = useDispatch()

  const DatesReduxS: any = useSelector((state: RootState) => state.Events.DatesRedux)

  
  const DatesStartRedux: any[] = useSelector((state: RootState) => state.Events.EventsDateStartRedux)
  const DatesEndRedux: any = useSelector((state: RootState) => state.Events.EventsDateEndRedux)
  // console.log(DatesReduxS)


  useEffect(() => {

    if (DatesReduxS[0] == 'range')
      setSelectDate(true)

    
    
    console.log(typeof(dayjs(now, dateFormatList)))
    
    // dispatch(EventsDateStartRedux(dayjs(now, dateFormatList)))
    // dispatch(EventsDateEndRedux(dayjs(now, dateFormatList)))
    // setDateRangeStart(dayjs(now, dateFormatList))
    // setDateRangeEnd(dayjs(now, dateFormatList))
  },[])



  const SetRangeByDefault = () => {
    setRangePickerOpen(true)
    dispatch(DatesRedux('range'))
    setDateRangeStart(dayjs(now, dateFormatList))
    setDateRangeEnd(dayjs(now, dateFormatList))
    setSelectDate(true)
    // dispatch(EventsPageNumberRedux(1))
  }
  

  const onChangeDate = (e: RadioChangeEvent) => {
    setRangePickerOpen(false)
    setSelectDate(false)
    dispatch(DatesRedux(e.target.value))
    // dispatch(EventsPageNumberRedux(1))
  }

  const onChangeRangePicker = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {

    if (dates) {  

      // console.log(dates[0]?.toDate())
      dispatch(DatesRedux('range'))
      setDateRangeStart(dates[0]?.toDate())
      setDateRangeEnd(dates[1]?.toDate())
      setRangePickerOpen(false)

      // dispatch(EventsDateStartRedux(dates[0]?.toDate()))
      // dispatch(EventsDateEndRedux(dates[1]?.toDate()))

      // console.log('From: ', dates[0]?.toDate(), ', to: ', dates[1]?.toDate());
      // console.log('From: ', dateStrings, ', to: ', dateStrings[1]);

    } else if (dates == null) {

      setRangePickerOpen(true)
    }
    
  }
 

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    console.log(current)
    return current && current <= dayjs().endOf('day');
  };

  return (
    <>
      <div className={classes.divider}><br/><ClockCircleOutlined style={{ color: "#63584F", fontSize: '28px' }} /><span className={classes.dividerSpan3}>{t("Ημερομηνία")}</span></div>
      <Row>
        <Radio.Group defaultValue={checkedId && checkedId[0]}>
          {DatesData.map((item: any) => {
            return (
              <Col span={24} key={item.id} className={classes.checkboxGroup}>
                <Radio value={item.id} onChange={onChangeDate}>{t(item.item)}</Radio >
              </Col>
            )
          })}
          <Col span={24} className={classes.checkboxGroup}>
            <Radio value={'range'} onChange={ () => SetRangeByDefault() } >* {t('Έυρος ημερομηνιών')}</Radio >
            {/* <div className={classes.checkboxGroupRange}>* {t('Έυρος ημερομηνιών')}</div> */}
            
            <div style={{height: '20px'}}>
              {selectDate &&
                <RangePicker
                  // open={rangePickerOpen}
                  disabledDate={disabledDate}
                  bordered={false}
                  placeholder={[t('Από'), t('Έως')]}
                  format={dateFormatList}
                  onChange={onChangeRangePicker}
                  defaultValue={[dayjs(now, dateFormatList), dayjs(now, dateFormatList)]}
                  // defaultValue={[dayjs(new Date()), DatesEndRedux]}
                  locale={locale}
                />
              }
            </div>
          </Col>
        </Radio.Group>
      </Row>
    </>
  )
}

export default CheckBoxDatesComponent