import React from 'react'
import { Row, Col, Button, Carousel } from 'antd'
import classes from "./css/Events.module.css"
import { useTranslation } from "react-i18next"
import { EnvironmentOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'
import { MunicipalitiesData } from '../../../components/CheckBoxGroup/municipalitiesForHome'
import { useEventsMap } from '../../../hooks/useEvent'
import { settingsCarouselEvents } from '../../../components/ArrowsForCarousel/Categories'

import EventComponent from '../../../components/Box/EventComponent'

// Redux
import { useDispatch } from 'react-redux'
import {  EventsRedux, DatesRedux, MunicipalityRedux } from '../../../redux/mapPage/event'

interface Props{
  color?: string
}

const Events: React.FC<Props> = ({ color }) => {

  const { isLoading, error, Events } = useEventsMap()
  const dispatch = useDispatch()

  const { t } = useTranslation()
  const navigate = useNavigate()

  const NavigateToEvents = (municipality:string) => {
    dispatch(EventsRedux(['Συναυλίες', 'Θέατρο', 'Έκθεση', 'Κινηματογράφος', 'Χορός', 'Εκδηλώσεις', 'Ρεσιτάλ', 'Συνέδριο']))
    dispatch(DatesRedux('today'))
    dispatch(MunicipalityRedux([municipality]))
    navigate("events")
  }


  return (
    <>
   
      
      <div className={classes.containerSEX}>
       
        <Row justify="center">
          <Col span={24}>
            <div className={color == 'red' ? classes.title : classes.titleGrey}>
              {t('Εκδηλώσεις στα Χανιά')}
            </div>
          </Col>
        </Row>

        <Row justify="center">
          <Col xs={{ span:22 }} lg={{ span:22 }}>
            <div className={classes.subTitle}>
            {t('Όλες οι εκδηλώσεις που συμβαίνουν αυτή την περίοδο')}
            </div>
          </Col>
        </Row>
      </div> 
      <div className={classes.container}>  


        <Row justify="center" className={classes.EventsContainer}>
          <Col xs={{ span: 22 }} lg={{ span: 24 }}>
            <Carousel {...settingsCarouselEvents} slidesToShow={Events.length <=3 ? 1 : 3}>
            { Events.map((event: any) => {
              return (
                    
                  <EventComponent 
                    key={event.id}
                    id_item={event.id_item}
                    image={event.image}
                    title={event.title}
                    price={event.price}
                    time_periods={event.time_periods}
                    place={event.place}
                    category_id={event.category_id}
                    category_type={event.category_type}
                  />
                )
              })  
            }
           </Carousel>
          </Col>
        </Row>

        <div style={{marginTop: 120}}></div>
       
        <Row justify="center">
          <Col xs={{ span: 22 }} lg={{ span: 16 }}>
            <div className={classes.MoreBtnContainer}>
            <Link to={`/events`}>
              <Button
                type="primary"
                className={classes.MoreBtn}
              // onClick={() => enterLoading(1)}
              >
                {t('Όλες οι Εκδηλώσεις')}&nbsp;&nbsp;<ArrowRightOutlined style={{ fontWeight: 'bold' }} />
                </Button>
              </Link>
            </div>
          </Col>
        </Row>

      </div>
    </>
  )
}

export default Events