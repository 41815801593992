import React, { useState, useRef } from 'react'
import { Col, Row, Button, Rate, Popconfirm } from 'antd'
import classes from "./Pois.module.css"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../../../utils/auth"
import { HeartOutlined, PlusCircleOutlined, CloseCircleOutlined, ExclamationOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import LoginOrRegisterModal from '../../../components/Box/LoginOrRegisterModal'
import GlobusSvg from '../../../img/svg/globus'
import MapSvg from '../../../img/svg/map'
import Spinner from '../../../components/PageComponents/Spinner'

import TypesOfRoute from '../../../components/TypesOfRoute'

import { ChangeMunicipality } from '../../../components/Box/ChangeMunicipality'
interface Props{
  id: string
  image: string
  title: string
  rate: number
  text: string
  municipality: string
  category: string
  isAuth?: boolean
  isAddOrDelete?: boolean
  onClickAddOrRemovePois: (text: string, idPois: string, AddOrDelete: number) => void,
  onClickAddRemovePois: (idPois: string) => void,
  imageSize?: string
  codesPerRoute?: string
}

const PoisComponent: React.FC<Props> = ({id, image, title, rate, text, municipality, category, isAuth, isAddOrDelete, onClickAddOrRemovePois, onClickAddRemovePois, imageSize, codesPerRoute }:Props) => {

  const { t } = useTranslation()
  const navigate = useNavigate()
  const auth = useAuth()
  const Municipality = ChangeMunicipality(municipality)

  const [size, setSize] = useState<any>({});
  const imgRef = useRef<any>(null)

  return (
  <>
    <div className={classes.PointContainer}>
      <Row>

        <Col xs={24} sm={24} md={5} lg={5}>
            <Link to={`/exploration/${id}`} >
              { imageSize =='small' ? <div>
                  <img
                    ref={imgRef}
                    onLoad={(e: any) => {
                      setSize({
                        height: e.target.naturalHeight,
                        width: e.target.naturalWidth
                      })
                    }}
                    src={image !== '' ? image : '../img/defaultPoisImage.jpg'}
                    className={size.height > size.width ? classes.PointImgPortrait : classes.PointImgSmall}
                    alt={title} />
                </div>
                  :
                  <div>
                    <img
                      ref={imgRef}
                      onLoad={(e: any) => {
                        setSize({
                          height: e.target.naturalHeight,
                          width: e.target.naturalWidth
                        })
                      }}
                      src={image !== '' ? image : '../img/defaultPoisImage.jpg'}
                      className={size.height > size.width ? classes.PointImgPortrait : classes.PointImg}
                      alt={title} />
                  </div>
              }
          </Link>
        </Col>

        <Col xs={24} sm={24} md={14} lg={14}>

          <div className={classes.PointCenter}>
            
            <div className={classes.PointCenterLeft} onClick={() => navigate(`/exploration/${id}`, { replace: false  })}>
              {title}
            </div>
              
           
          </div>

          <div className={classes.PointCenter}>
            <p className={classes.PointCenterCenterP}>{text}...</p>
            </div>
            
            <div className={classes.typeDiv}>
              
              {codesPerRoute !== '' &&
              
              <TypesOfRoute codesPerRoute={codesPerRoute} />  
              
              }


          </div>
          {/* <div className={classes.PointCenterBottom}>
            <div className={classes.PointCenterButtomLeft}>
                <div><GlobusSvg width={25} height={25} fill="#275674" />
                  <span className={classes.PointTextBtm}>
                    { id === '1_48' ? t('FaraggiSamarias') : id === '1_290' ? t('SelinoKissamos') : t(Municipality) }
                  </span>
                </div>
            </div>
            <div className={classes.PointCenterButtomRight}>
              <div><MapSvg width={25} height={25} fill="#275674" /><span className={classes.PointTextBtm}>{category.charAt(0).toUpperCase() + category.slice(1).toLowerCase()}</span></div>
            </div>
          </div> */}
            

        </Col>

        <Col xs={24} sm={24} md={5} lg={5}>
            <div className={classes.centerVertically}>
            
            <Button
              size='large'
              icon={< PlusCircleOutlined />}
              className={classes.PointMoreBtn}
              onClick={() => navigate(`/exploration/${id}`)}>
              {t('Περισσότερα')}
            </Button> 
          </div>
        </Col>

      </Row>
      </div>
    </>
  )
}

export default PoisComponent
