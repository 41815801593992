export const AboutUsData = [{
'gr':{
  id: 1,
  title: 'Σχετικά με το έργο',
  text:`
<div>
  O παρόν δικτυακός τόπος chaniaroutes.gr αναπτύχθηκε στο πλαίσιο του έργου <strong>«Δράσεις Σήμανσης και Ανάδειξης θεματικών διαδρομών περιοχής ΒΑΑ του Δήμου Χανίων»</strong>.  Χρηματοδοτήθηκε από το ΕΠ «Κρήτη» 2014-2020 (MIS :5017655) από την Ευρωπαϊκή Ένωση - Ευρωπαϊκό Ταμείο Περιφερειακής Ανάπτυξης (ΕΤΠΑ) και από εθνικούς πόρους .
</div>
<div>
 Το έργο αυτό με δικαιούχο τον Δήμο Χανίων έχει ως αντικείμενο την  σήμανση και ανάδειξη ενός δικτύου θεματικών διαδρομών εντός της εγκεκριμένης περιοχής της Στρατηγικής Βιώσιμης Αστικής Ανάπτυξης (ΣΒΑΑ) του Δήμου Χανίων, μέσα από κατάλληλες δράσεις φυσικής, ψηφιακής και έντυπης μορφής, με σκοπό την προβολή και την ενίσχυση της επισκεψιμότητας αξιόλογων σημείων ενδιαφέροντος. 
</div>
<div>
  Το δίκτυο των θεματικών διαδρομών αποτελείται από 5 διαδρομές (Διαδρομή Η: Μνημεία της Ιστορίας, Διαδρομή F: Οχυρώσεις, Διαδρομή C: Πολιτισμός και Χώροι Εκδηλώσεων, Διαδρομή Α: Εκτός των Τειχών, Διαδρομή S: Ακτογραμμή) και περιλαμβάνει 115 σημεία ενδιαφέροντος.
</div>
<div>
 Οι στόχοι του έργου θα επιτευχθούν με: α) Την επιμέλεια, σχεδιασμό, κατασκευή και τοποθέτηση πληροφοριακών πινακίδων σε όλα τα σημεία ενδιαφέροντος του δικτύου των  θεματικών διαδρομών. β) Την ανάπτυξη διαδικτυακού πληροφοριακού συστήματος, μιας εφαρμογής για φορητές συσκευές (App) και την εγκατάσταση μιας οθόνης αφής εσωτερικού χώρου για την προβολή της δικτυακής πύλης. γ) Την επιμέλεια, σχεδιασμό και εκτύπωση έντυπου υλικού (χάρτες, τουριστικός οδηγός, φωτογραφικό λεύκωμα κλπ).
</div>


<div>
  Δείτε περισσότερες πληροφορίες πλαίσιο για το έργο εδώ: 
  <a href="https://www.chania.gr/enimerosi/erga-espa/espa-thematikes.html" target="_blank" style="color: rgb(10, 85, 114);
  text-decoration: underline;">
  «Δράσεις Σήμανσης και Ανάδειξης θεματικών διαδρομών περιοχής ΒΑΑ του Δήμου Χανίων»
</a>
</div>
`
  },
  
'en':{
  id: 2,
  title: 'About us',
  text:`
<div>
The present website chaniaroutes.gr was developed in the framework of the project <strong>"Actions for marking and highlighting thematic routes in the BAA region of the Municipality of Chania"</strong>.  It was funded by the OP "Crete" 2014-2020 (MIS :5017655) from the European Union - European Regional Development Fund (ERDF) and from national resources.
</div>

<div>
This project, with the Municipality of Chania as beneficiary, aims to mark and promote a network of thematic routes within the approved area of the Sustainable Urban Development Strategy (SUDA) of the Municipality of Chania, through appropriate physical, digital and printed actions, in order to promote and enhance the visibility of significant points of interest.
</div>

<div>
The network of thematic routes consists of 5 routes (Route H: Historical Monuments, Route F: Fortifications, Route C: Culture and Event Venues, Route A: Outside the Walls, Route S: Coastline) and includes 115 points of interest.
</div>

<div>
The objectives of the project will be achieved by: a) The curation, design, construction and installation of information signs at all points of interest in the network of thematic routes. b) The development of a web-based information system, a mobile device application (App) and the installation of an indoor touch screen to display the web portal. c) The editing, design and printing of printed material (maps, tourist guide, photo album, etc.).
</div>

<div>
See more information about the project here (in Greek):  <a href="https://www.chania.gr/enimerosi/erga-espa/espa-thematikes.html" target="_blank" style="color: rgb(10, 85, 114);
text-decoration: underline;">"Actions for marking and highlighting thematic routes in the BAA region of the Municipality of Chania"</a>

</div>
`
},
'fr':{
  id: 3,
  title: 'À propos de nous', 
  text:`
<div>
Le présent site web chaniaroutes.gr a été développé dans le cadre du projet <strong>"Actions pour le marquage et la mise en valeur des itinéraires thématiques dans la région BAA de la municipalité de Chania"</strong>.  Il a été financé par le PO "Crète" 2014-2020 (MIS :5017655) de l'Union européenne - Fonds européen de développement régional (FEDER) et par des ressources nationales.
</div>
<div>
Ce projet, dont la municipalité de Chania est bénéficiaire, vise à marquer et à promouvoir un réseau d'itinéraires thématiques dans la zone approuvée de la stratégie de développement urbain durable (SUDA) de la municipalité de Chania, par le biais d'actions physiques, numériques et imprimées appropriées, afin de promouvoir et d'améliorer la visibilité des points d'intérêt significatifs.
</div>
<div>
Le réseau d'itinéraires thématiques se compose de 5 itinéraires (Itinéraire H : Monuments historiques, Itinéraire F : Fortifications, Itinéraire C : Sites culturels et événementiels, Itinéraire A : Hors les murs, Route S : Littoral) et comprend 115 points d'intérêt.
</div>
<div>
Les objectifs du projet seront atteints par : a) La conservation, la conception, la construction et l'installation de panneaux d'information à tous les points d'intérêt du réseau d'itinéraires thématiques. b) Le développement d'un système d'information basé sur le web, une application pour appareil mobile (App) et l'installation d'un écran tactile intérieur pour afficher le portail web. c) L'édition, la conception et l'impression de matériel imprimé (cartes, guide touristique, album de photos, etc.).
</div>
<div>
See more information about the project here (in Greek):  <a href="https://www.chania.gr/enimerosi/erga-espa/espa-thematikes.html" target="_blank" style="color: rgb(10, 85, 114);
text-decoration: underline;">"Actions for marking and highlighting thematic routes in the BAA region of the Municipality of Chania"</a>

</div>
  `
  },

'de':{
  id: 4,
  title: 'Über uns', 
  text:`
<div>
  Die vorliegende Website chaniaroutes.gr wurde im Rahmen des Projekts <strong>"Maßnahmen zur Markierung und Hervorhebung thematischer Routen in der BAA-Region der Gemeinde Chania"</strong> entwickelt.  Es wurde im Rahmen des OP "Kreta" 2014-2020 (MIS :5017655) von der Europäischen Union - Europäischer Fonds für regionale Entwicklung (EFRE) und aus nationalen Mitteln finanziert.
</div>
<div>
  Dieses Projekt, mit der Gemeinde Chania als Begünstigtem, zielt darauf ab, ein Netzwerk von thematischen Routen innerhalb des genehmigten Gebiets der Strategie für nachhaltige Stadtentwicklung (SUDA) der Gemeinde Chania durch geeignete physische, digitale und gedruckte Aktionen zu markieren und zu fördern, um die Sichtbarkeit von bedeutenden Sehenswürdigkeiten zu fördern und zu verbessern.
</div>
<div> 
  Das Netz thematischer Routen besteht aus 5 Routen (Route H: Historische Denkmäler, Route F: Festungsanlagen, Route C: Kultur und Veranstaltungsorte, Route A: Außerhalb der Stadtmauern, Route S: Küstenlinie) und umfasst 115 Sehenswürdigkeiten.
</div>
<div>
  Die Ziele des Projekts werden erreicht durch: a) die Gestaltung, das Design, den Bau und die Installation von Informationsschildern an allen Sehenswürdigkeiten des thematischen Routennetzes. b) die Entwicklung eines webbasierten Informationssystems, einer Anwendung für mobile Geräte (App) und die Installation eines Touchscreens in Innenräumen zur Anzeige des Webportals. c) die Bearbeitung, das Design und den Druck von gedrucktem Material (Karten, Reiseführer, Fotoalbum, etc.).
</div>
<div> 
  Weitere Informationen über das Projekt finden Sie hier (auf Griechisch):<a href="https://www.chania.gr/enimerosi/erga-espa/espa-thematikes.html" target="_blank" style="color: rgb(10, 85, 114);
  text-decoration: underline;">"Aktionen zur Markierung und Hervorhebung thematischer Routen in der BAA-Region der Gemeinde Chania"</a>
  
</div>
  `
},

'ru': {
  id: 5,
  title: 'О нас',
  text: `
<div>
Настоящий сайт chaniaroutes.gr был разработан в рамках проекта <strong>"Действия по маркировке и выделению тематических маршрутов в районе BAA муниципалитета Ханьи"</strong>.  Он финансировался в рамках ОП "Крит" 2014-2020 (MIS :5017655) из средств Европейского союза - Европейского фонда регионального развития (ERDF) и из национальных ресурсов.
</div>
<div>
Данный проект, бенефициаром которого является муниципалитет Ханьи, направлен на маркировку и продвижение сети тематических маршрутов в пределах утвержденной территории Стратегии устойчивого городского развития (СУДА) муниципалитета Ханьи посредством соответствующих физических, цифровых и печатных мероприятий с целью продвижения и повышения видимости значимых достопримечательностей.
</div>
<div>
Сеть тематических маршрутов состоит из 5 маршрутов (маршрут H: исторические памятники, маршрут F: фортификационные сооружения, маршрут C: места проведения культурных мероприятий, маршрут A: За стенами, Маршрут S: Береговая линия) и включает 115 достопримечательностей.
</div>
<div>
Цели проекта будут достигнуты путем: a) Курирования, проектирования, строительства и установки информационных указателей во всех точках интереса в сети тематических маршрутов. b) Разработки информационной веб-системы, приложения для мобильных устройств (App) и установки сенсорного экрана в помещении для отображения веб-портала. c) Редактирования, дизайна и печати печатных материалов (карты, путеводитель, фотоальбом и т.д.).
</div>
<div>
Более подробную информацию о проекте можно найти здесь (на греческом языке): <a href="https://www.chania.gr/enimerosi/erga-espa/espa-thematikes.html" target="_blank" style="color: rgb(10, 85, 114);
text-decoration: underline;">"Мероприятия по маркировке и выделению тематических маршрутов в районе BAA муниципалитета Ханьи"</a>
</div>
  `
  }
}
]
