
import ky from 'ky'
import axios from 'axios'
import { useState, useEffect } from "react"
import {API, APIRoutes, Header, HeaderRoutes} from './configuration'
import i18n from '../../src/translation'

// Redux
import { useDispatch } from 'react-redux'
import { SetRoutesArray, SetRoutesArray2  } from '../redux/mapPage/routesArray'

export const axiosRoutes = {

  async getRoute(id: string) {
    const data: any = await ky.get(`${APIRoutes}/route?id=${id}&fetch_files=1&lang=${i18n.language == 'gr' ? 'el' : i18n.language }`, {headers: HeaderRoutes}).json()
    return data.success
  },

  async getRoutes() {
    
    //////////////////////////////////////////////////////////////////////////
    let arrayForSearch: any = [];

    axios.all([
      axios.get(`${APIRoutes}/routes?lang=en`, {headers: HeaderRoutes}), 
      axios.get(`${APIRoutes}/routes?lang=el`, { headers: HeaderRoutes }),
      axios.get(`${APIRoutes}/routes?lang=de`, { headers: HeaderRoutes }),
      axios.get(`${APIRoutes}/routes?lang=fr`, { headers: HeaderRoutes }), 
      axios.get(`${APIRoutes}/routes?lang=ru`, {headers: HeaderRoutes}), 
    ])
    .then(axios.spread((en, el, de, fr, ru ) => {
      arrayForSearch.push(
        { id: 0, code: 'en', array: en.data.success },
        { id: 0, code: 'el', array: el.data.success },
        { id: 0, code: 'de', array: de.data.success },
        { id: 0, code: 'fr', array: fr.data.success },
        { id: 0, code: 'ru', array: ru.data.success }
      )
    }));
    //////////////////////////////////////////////////////////////////////////////


    const data:any = await ky.get(`${APIRoutes}/routes?lang=${i18n.language == 'gr' ? 'el' : i18n.language }`, {headers: HeaderRoutes}).json()
    return [data.success, arrayForSearch]
  },


  async getArrayPoisFromRoute(id: string) {

    let ArrayRoutes = ['3_2', '3_3', '3_4', '3_6', '3_1']
    let routeData: any
    let users: any = [];

    for (let i = 0; i < ArrayRoutes.length; i++) { // ForEach Not Works

      routeData = await ky.get(`${APIRoutes}/route?id=${ArrayRoutes[i]}&fetch_files=1&lang=${i18n.language == 'gr' ? 'el' : i18n.language}`, { headers: HeaderRoutes }).json()
    
       if(routeData.success.pois.some((item: any) => item.id === id)){
          users.push(routeData.success)
          break;
        }
    }
    
   return users
  },

}