import { createSlice } from '@reduxjs/toolkit'

interface RoutesArray{ value: string }
interface RoutesArray2{ value: string }
interface PoisesArrayForSearch{ value: string}
interface RoutesState {
  Routes: RoutesArray[]
  Routes2: RoutesArray2[]
  PoisesArrayForSearch: PoisesArrayForSearch[]
}

const initialState: RoutesState = {
  Routes: [],
  Routes2: [],
  PoisesArrayForSearch: []
};

export const RoutesArrSlice = createSlice({
  name: 'RoutesArr',
  initialState,
  reducers: {

    SetRoutesArray: (state, action) => {
      state.Routes = []
      state.Routes.push(action.payload)

    },

    SetRoutesArray2: (state, action) => {
      state.Routes2 = []
      state.Routes2.push(action.payload)

    },

    SetPoisesArrayForSearch: (state, action) => {
      state.PoisesArrayForSearch = []
      state.PoisesArrayForSearch.push(action.payload)

    },
   
  },
})

export const { SetRoutesArray, SetRoutesArray2, SetPoisesArrayForSearch } = RoutesArrSlice.actions
export default RoutesArrSlice.reducer 