import React from "react"
import { Row, Col } from 'antd'
import classes from './styles.module.css'
import i18n from '../../../../src/translation'
import { useTranslation } from "react-i18next"
interface Props{
  title: string
  subTitle: string
}

const TitleAndSubtitle: React.FC<Props> = ({ title, subTitle }: Props) => {


  const { t } = useTranslation()


  return (
      <div className={classes.container}>
        <Row justify="center">
          <Col span={20}>
            <div className={classes.title}>
            { title }
            </div>
          
            {i18n.language !== 'gr' && 
            <div className={classes.subTitle2}>
                {t('Events are currently in Greek language.')}
            </div>
            }
          
            <div className={classes.subTitle}>
            { subTitle }
            </div>
          </Col>
        </Row>
      </div>
  )
}

export default TitleAndSubtitle
