export const SearchPoisTitles = [
  
  {value: "Ανασκαφή «Βακαλουνάκη»"},
  {value: "Ανασκαφή «Λιονάκη-Βλαμά0κη»"},
  {value: "Ανασκαφή «Μαθιουδάκη»"},
  {value: "Ανασκαφή «Νότια του Τείχους»"},
  {value: "Ανασκαφή «Παπαδόπουλου»"},
  {value: "Αρχαιολογικό Μουσείο Χανίων"},
  {value: "Αυστριακό Προξενείο επί Κρητικής Πολιτείας"},
  {value: "Βενιζέλειο Ωδείο"},
  {value: "Βίλλα Κούνδουρου"},
  {value: "Βυζαντινή & Μεταβυζαντινή Συλλογή Χανίων"},
  {value: "Γαλλική Σχολή"},
  {value: "Γαλλικό Προξενείο επί Κρητικής Πολιτείας"},
  {value: "Γενοβέζικος (ή Γενουάτικος) πύργος"},
  {value: "Γερμανικό Προξενείο επί Κρητικής Πολιτείας"},
  {value: "Γιαλί Τζαμί"},
  {value: "Δεσποτικό Χανίων"},
  {value: "Δευκαλίωνος"},
  {value: "Δημοτική Αγορά"},
  {value: "Δημοτική βιβλιοθήκη"},
  {value: "Δημοτική Πινακοθήκη"},
  {value: "Δημοτικός Κήπος"},
  {value: "Δημοτικός κινηματογράφος «Κήπος»"},
  {value: "Διαβατικό Ενετικού Μεγάρου Renier, Παρεκκλήσι Οικογένειας Renier"},
  {value: "Δικαστικό Διοικητικό Μέγαρο"},
  {value: "Διοικητήριο επί Κρητικής Πολιτείας («Παλιά Μεραρχία»)"},
  {value: "Εβραϊκή Συναγωγή Ετζ Χαγίμ"},
  {value: "Εθνικό Ίδρυμα Ερευνών & Μελετών “Ελευθέριος Βενιζέλος”"},
  {value: "Εκβολή Κλαδισού ποταμού"},
  {value: "Ελληνικό Προξενείο επί Κρητικής Πολιτείας"},
  {value: "Ελληνοσουηδική ανασκαφή"},
  {value: "Ενετικά Νεώρια"},
  {value: "Ενετικό κτήριο"},
  {value: "Ενετικό κτήριο (Fagotto)"},
  {value: "Ενετικό κτήριο (ξενοδοχείο Alcanea)"},
  {value: "Ενετικό κτήριο (πρώην οικία Σταυρουλάκη)"},
  {value: "Ενετικό κτήριο (σημερινό ξενοδοχείο OFF)"},
  {value: "Επιπρομαχώνας Priuli"},
  {value: "Επιπρομαχώνας Αγίας Αικατερίνης"},
  {value: "Θέατρο Ανατολικής Τάφρου"},
  {value: "Θέατρο “Δημήτρης Βλησίδης”"},
  {value: "Ιερός Ναός Αγίου Φραγκίσκου"},
  {value: "Ιστορική, Λαογραφική & Αρχαιολογική Εταιρεία Κρήτης (Κτίριο ΙΛΑΕΚ)"},
  {value: "Ιστορικό Αρχείο Κρήτης"},
  {value: "Ιταλικός Στρατώνας"},
  {value: "Καθεδρικός Ναός Εισοδίων ή Τριμάρτυρη)"},
  {value: "Καθολική Εκκλησία"},
  {value: "Κατοικία Κυριάκου Κων/νου Μητσοτάκη"},
  {value: "Κατοικία του εμπόρου Ναξάκη"},
  {value: "Κέντρο Αρχιτεκτονικής της Μεσογείου (Μεγάλο Αρσενάλι) "},
  {value: "Κτήριο Εμμ. Παπαγιαννάκη"},
  {value: "Κτήριο πρώην Αγροτικής Τράπεζας"},
  {value: "Κτίριο Αγγέλας Ξηρουχάκη"},
  {value: "Κτίριο Αικατερίνης Σαριδάκη"},
  {value: "Κτίριο Γεωργίου Πανηγυράκη"},
  {value: "Κτίριο Γεωργίου Παπαδόπετρου"},
  {value: "Κτίριο Ιδιοκτησίας Μπολάκη Ελένης & Παναγιώτη"},
  {value: "Κτίριο Κωνσταντίνου Κατζουράκη"},
  {value: "Κτίριο Παναγιώτη Μουζουράκη"},
  {value: "Κτίριο Τράπεζας της Ελλάδος"},
  {value: "Κτίριο Χατζηδάκη & Παπαδονικολάκη"},
  {value: "Λιμανάκι Νέας Χώρας"},
  {value: "Μέγαρο Θεοδώρου Μανούσακα"},
  {value: "Μιναρές Αχμέτ Αγά"},
  {value: "Μνημείο πεσόντων Νεοχωριτών"},
  {value: "Μονή Santa Maria dei Miracolli"},
  {value: "Μονή Αγίου Νικολάου των Δομηνικανών"},
  {value: "Μονή της Santa Maria della Misericordia των Αυγουστιανών"},
  {value: "Μονή του San Salvatore των Φραγκισκανών"},
  {value: "Ναός Αγίας Αικατερίνης (Σπλάντζια)"},
  {value: "Ναός Αγίας Ειρήνης"},
  {value: "Ναός Αγίας Κυριακής"},
  {value: "Ναός Αγίας Μαγδαληνής"},
  {value: "Ναός Αγίου Γεωργίου Κουμπελή"},
  {value: "Ναός Αγίου Ελευθερίου"},
  {value: "Ναός Αγίου Ρόκκου"},
  {value: "Ναός Αγίων Αναργύρων"},
  {value: "Ναός Γεννεσίου Της Θεοτόκου"},
  {value: "Ναυτικό Μουσείο Κρήτης"},
  {value: "Νεώρια Moro"},
  {value: "Οικία Βαλέριου Καλούτση"},
  {value: "Οικία-Μουσείο Βενιζέλου"},
  {value: "Οικία Πέτρου Μαρκαντωνάκη (Εριέττας Χορτάτζη-Μαρκαντωνάκη)"},
  {value: "Οικία του χρυσοχόου Ανίκητου Γαροφαλάκη"},
  {value: "Οικία Φαιναρέτης Μιτσοτάκη - Βρετανικό Προξενείο επί Κρητικής Πολιτείας"},
  {value: "Οχυρώσεις"},
  {value: "Παλάτι Πρίγκιπα Γεωργίου"},
  {value: "Παλιό Επισκοπείο"},
  {value: "Παραλία «Κουμ Καπί»"},
  {value: "Πλατεία 1866"},
  {value: "Πλατεία Τάλω"},
  {value: "Πνευματικό Κέντρο Χανίων"},
  {value: "Προμαχώνας Lando (ή Αγίου Δημητρίου)"},
  {value: "Προμαχώνας Mocenigo"},
  {value: "Προμαχώνας Piatta Forma"},
  {value: "Προμαχώνας Sabbionara"},
  {value: "Προμαχώνας San Salvatore"},
  {value: "Προμαχώνας Santa Lucia"},
  {value: "Προμαχώνας Αγίου Νικολάου του Μώλου"},
  {value: "Πρώην κατοικία βουλευτή Αντώνη Κατζουράκη (Κτιρίο Ιδιοκτ. Κλέαρχου Μαρκαντωνάκη)"},
  {value: "Πρώην Κατοικία Βουλευτή Κωνσταντίνου Φούμη"},
  {value: "Πρώην κατοικία Βουλευτή Χαράλαμπου Πωλογιώργη"},
  {value: "Πρώην κατοικία γερουσιαστή Μπιστολάκη (Κτίριο ΤΕΕ, Τμήμα Δυτικής Κρήτης)"},
  {value: "Πρώην Κατοικία Διοικητή Τράπεζας Φίλιππου Ντόκου "},
  {value: "Πρώην κατοικία εμπόρου Νικολάου Κουφάκη"},
  {value: "Πρώην κατοικία Λαντράκη"},
  {value: "Πρώην Οικία Άγγλου Εμποροπλοιάρχου Σώου"},
  {value: "Πύλη Sabbionara"},
  {value: "Ρολόι Δημοτικού Κήπου"},
  {value: "Ρώσικος Στρατώνας (Κέντρο Αναβίωσης Παραδοσιακών Επαγγελμάτων)"},
  {value: "Στιβανάδικα/Οδός Σκρίδλωφ"},
  {value: "Σχολικά κτίρια Κοραή"},
  {value: "Ταμπακαριά"},
  {value: "Ταμπακαριά (οδού Βιβιλάκη)"},
  {value: "Τάφοι Βενιζέλων"},
  {value: "Υπόγεια κρήνη Σπλάντζιας"},
  {value: "Φάρος λιμένα Χανίων"},
  {value: "Φιλολογικός Σύλλογος “Ο Χρυσόστομος”"},
  {value: "Φράγκικο Νεκροταφείο Χανίων"},
  {value: "Φρούριο Φιρκά"},
  {value: "Χαμάμ στην οδό Ζαμπελίου"},
  {value: "Χαμάμ στην οδό Κατρέ"},
  {value: "Χαμάμ στην οδό Χάληδων"},
  {value: "Χώρος Πολλαπλών Δραστηριοτήτων - Θέατρο Μίκης Θεοδωράκης"},
  {value: "Ακτογραμμή - Φύση"},
  {value: "Εκτός Των Τειχών"},
  {value: "Μνημεία Της Ιστορίας"},
  {value: "Οχυρώσεις"},
  {value: "Χώροι Πολιτισμού και Εκδηλώσεων"},
  {value: "Ανοιχτά Πανιά 2023 – Crossroad"},
  {value: "Ανοιχτά Πανιά 2023 – «Γράμμα από το μέλλον στους ισοβίτες του Ιτζεδίν»"},
  {value: "Αρχιτεκτονική Εκπαίδευση και Πράξη: οι διεθνείς προκλήσεις και η Ελλάδα"},
  {value: "Άμλετ/Κολτές"},
  {value: "Ανοιχτά Πανιά 2023 – Desire is a place"},
  {value: "“Κανονική Δουλειά”"},
  {value: "Ανοιχτά Πανιά 2023 – Πες το αθέατο/Tell the unseen"},
  {value: "Συνέδριο Αρχιτεκτονικής και Τουρισμού"},
  {value: "Η ΑΦΡΟΔΙΤΗ ΜΕ ΤΗ ΓΟΥΝΑ"},
  {value: "ΤΑΞΗ"},
  {value: "Κωνσταντίνος Βολανάκης: Νόστος της θάλασσας"},
  {value: "Μίκης Θεοδωράκης (1925-2021) – έκθεση"},
  {value: "Οι Φρουροί Της Σούδας (έκθεση)"},
  
  
  
  
  
  {value: "1866 Square"},
  {value: "Agia Ekaterini Rampart"},
  {value: "Ahmet Aga Minaret"},
  {value: "Archaeological Museum of Chania"},
  {value: "Augustinian Monastery of Santa Maria della Misericordia"},
  {value: "Austrian Consulate during the Cretan State"},
  {value: "Bank of Greece building"},
  {value: "Bastion of Agios Nikolaos Molos"},
  {value: "Bastion Piatta Forma"},
  {value: "Bastion Santa Lucia"},
  {value: "Building of Angela Xirouhaki"},
  {value: "Building of Ekaterini Saridaki"},
  {value: "Building of Eleni & Panagiotis Mpolaki"},
  {value: "Building of Emmanuel Papagiannakis"},
  {value: "Building of Georgios Papadopetros"},
  {value: "Building of Konstantinos Katzourakis"},
  {value: "Building of Panagiotis Mouzourakis"},
  {value: "Byzantine & Post-Byzantine Collection of Chania"},
  {value: "Cathedral of the Presentation of the Virgin Mary (or Trimartyri)"},
  {value: "Catholic Church"},
  {value: "Center of Mediterranean Architecture (Great Shipyard)"},
  {value: "Chania Catholic Cemetery (French Cemetery)"},
  {value: "Chania harbor lighthouse"},
  {value: "Church of Agioi Anargyroi"},
  {value: "Church of Agios Rokkos"},
  {value: "Church of of Saint Francis"},
  {value: "Church of Saint Ekaterini (Splantzia)"},
  {value: "Church of Saint Eleftherios"},
  {value: "Church of Saint Georgios Koumpelis"},
  {value: "Church of Saint Irene"},
  {value: "Church of Saint Kyriaki"},
  {value: "Church of Saint Magdalene"},
  {value: "Church of the Nativity of the Virgin"},
  {value: "Cultural Center of Chania"},
  {value: "Defkalionos"},
  {value: "Despotiko of Chania"},
  {value: "'Dimitris Vlisidis' Theater"},
  {value: "Dominican Monastery of Saint Nicholas"},
  {value: "East Moat Theatre"},
  {value: "'Eleftherios Venizelos' National Research & Studies Foundation"},
  {value: "Etz Hayyim Synagogue"},
  {value: "Excavation 'Lionaki-Vlamaki'"},
  {value: "Excavation 'Mathioudakis'"},
  {value: "Excavation 'Papadopoulos'"},
  {value: "Excavation 'South of the Wall'"},
  {value: "Excavation 'Vakalounaki'"},
  {value: "Firkas Fortress "},
  {value: "Former Agricultural Bank Building"},
  {value: "Former residence of Lantrakis"},
  {value: "Former Residence of Member of Parliament Charalambos Pologiorgis"},
  {value: "Former residence of merchant Nikolaos Koufakis"},
  {value: "Former residence of MP Antonios Katzourakis (Building of Klearchos Markantonakis)"},
  {value: "Former Residence of MP Konstantinos Foumis"},
  {value: "Former residence of Senator Mpistolakis (Building of the Technical Chamber of Greece / TEE, Department of West Crete)"},
  {value: "Former residence of the Bank Manager Filippos Ntokos"},
  {value: "Fortifications"},
  {value: "Franciscan Monastery of San Salvatore"},
  {value: "French Consulate during Cretan State"},
  {value: "French School"},
  {value: "Genoese tower"},
  {value: "Georgios Panigyrakis' Building"},
  {value: "German Consulate during the Cretan State"},
  {value: "Greek Consulate during the Cretan State"},
  {value: "Greek-Swedish excavation"},
  {value: "Hammam on Halidon Street"},
  {value: "Hammam on Katre Street"},
  {value: "Hammam on Zambeliou Street"},
  {value: "Hatzidakis & Papadonikolakis building"},
  {value: "Headquarters of the Cretan State ('Old Military Division')"},
  {value: "Historical Archives of Crete"},
  {value: "Historical, Folklore & Archaeological Society of Crete (ILAEK)"},
  {value: "House of Fainareti Mitsotaki - British Consulate during the Cretan State"},
  {value: "House of goldsmith Anikitos Garofalakis"},
  {value: "House of Petros Markantonakis (Erietta Hortatzi-Markantonaki)"},
  {value: "House of Valerios Kaloutsis"},
  {value: "Italian Barracks"},
  {value: "Judicial – Administrative Palace"},
  {value: "Kladisos river estuary"},
  {value: "'Kum Kapi' Beach"},
  {value: "Lando (or Agios Dimitrios) Bastion"},
  {value: "Mansion of Theodoros Manousakas"},
  {value: "Maritime Museum of Crete"},
  {value: "Mocenigo Bastion"},
  {value: "Monastery of Santa Maria dei Miracolli"},
  {value: "Monument to fallen residents of Nea Chora"},
  {value: "Municipal Art Gallery"},
  {value: "Municipal Cinema 'Kipos'"},
  {value: "Municipal Garden"},
  {value: "Municipal Garden Clock tower"},
  {value: "Municipal Library"},
  {value: "Municipal Market"},
  {value: "Nea Chora Harbour"},
  {value: "Neoria Moro"},
  {value: "Old Bishopric"},
  {value: "Passage of Renier Venetian Mansion, Chapel of Renier Family"},
  {value: "Philological Association 'Chrysostomos'"},
  {value: "Prince George's Palace"},
  {value: "Priuli Rampart"},
  {value: "Residence of Kyriakos, son of Konstantinos Mitsotakis"},
  {value: "Residence of the English merchant Soos"},
  {value: "Residence of the merchant Naxakis"},
  {value: "Russian barracks"},
  {value: "Sabbionara Bastion"},
  {value: "Sabbionara Gate"},
  {value: "San Salvatore Bastion"},
  {value: "School buildings on Korai Street"},
  {value: "Stivanadika/Scridlof Street"},
  {value: "Talo Square"},
  {value: "Tampakaria"},
  {value: "Tampakaria (Vivilakis Street)"},
  {value: "Underground fountain of Splantzia"},
  {value: "Venetian building"},
  {value: "Venetian building (Alcanea Hotel)"},
  {value: "Venetian building (current OFF hotel)"},
  {value: "Venetian building (Fagotto)"},
  {value: "Venetian building (former residence of Stavroulakis)"},
  {value: "Venetian Shipsheds"},
  {value: "Venizelio Conservatory"},
  {value: "Venizelos’ Residence-Museum"},
  {value: "Venizelos Tombs"},
  {value: "Venue for Multiple Activities - Mikis Theodorakis Theater"},
  {value: "Villa Koundouros"},
  {value: "Yali Tzamisi"},
  {value: "Coastline-Nature"},
  {value: "Fortifications"},
  {value: "Outside the walls"},
  {value: "Places of Culture and Events"},
  
  
  
  
  
  
  {value: "Augustinenkloster von Santa Maria della Misericordia"},
  {value: "Ausgrabung „Lionakis-Vlamakis“"},
  {value: "Ausgrabung „Mathioudaki“"},
  {value: "Ausgrabung „Papadopoulos“"},
  {value: "Bastion des Agios Nikolaos tou Molou (des Piers)"},
  {value: "Bastion Piatta Forma"},
  {value: "Bastion San Salvatore"},
  {value: "Bastion Santa Luccia"},
  {value: "Byzantinische&Postbyzantinische Sammlung von Chania"},
  {value: "Das alte Episkopat"},
  {value: "Das archäologische Museum von Chania"},
  {value: "Das Hauptquartier des kretischen Staates (alte Division)"},
  {value: "Das Maritime Museum von Kreta"},
  {value: "Das Mediterrane Architekturzentrum (Großes Arsenal)"},
  {value: "Defkalionos"},
  {value: "Denkmal der gefallenen Neohoriten"},
  {value: "Der katholische Friedhof (Fränkischer Friedhof)"},
  {value: "Der Städtische Markt"},
  {value: "Despotiko von Chania"},
  {value: "Deutsches Konsulat während des kretischen Staates"},
  {value: "Die Stadtbibliothek"},
  {value: "Ehemalige Residenz des Abgeordneten Antonis Katzourakis (Gebäude im Besitz des Klearhos Markantonakis)"},
  {value: "Ehemalige Residenz des Abgeordneten Bistolakis (Gebäude der Technischen Kammer Griechenlands, Filiale von West-Kreta)"},
  {value: "Ehemalige Residenz des Abgeordneten Konstantinos Foumis"},
  {value: "Ehemalige Residenz des Bankdirektors Filippos Dokos"},
  {value: "Ehemalige Residenz Lantrakis"},
  {value: "Ehemaliger Wohnsitz des Abgeordneten Charalambos Pologiorgis"},
  {value: "Ehemaliger Wohnsitz des Kaufmanns Nikolaos Koufakis"},
  {value: "Festungen"},
  {value: "Festung Firka"},
  {value: "Französische Schule"},
  {value: "Französisches Konsulat während des kretischen Staates"},
  {value: "Gebäude der Angela Xirouhaki"},
  {value: "Gebäude der Bank von Griechenland"},
  {value: "Gebäude der ehemaligen Landwirtschaftsbank von Griechenland"},
  {value: "Gebäude des Abgeordneten und Senators Manoussos Koundouros"},
  {value: "Gebaude des Georgios Papadopetros"},
  {value: "Gebäude des Konstantinos Katzourakis"},
  {value: "Gebäude des Panagiotis Mouzourakis"},
  {value: "Gebäude Emm.Papagiannakis"},
  {value: "Gebäude Georgios Panigyrakis"},
  {value: "Gebäude Hatzidaki&Papadonikolaki"},
  {value: "Gebäude im Besitz von Bolaki Eleni&Panagiotis"},
  {value: "Gebäude von Ekaterini Saridaki"},
  {value: "Geistliches Zentrum  von Chania"},
  {value: "Genuesischer Turm"},
  {value: "Gesellschaft für Geschichte, Folklore und Archäologie Kretas (ILAEK)"},
  {value: "Gräber der Venizelos Familie"},
  {value: "Griechisches Konsulat während des kretischen Staates"},
  {value: "Griechisch-schwedische Ausgrabung"},
  {value: "Hafen von Nea Chora"},
  {value: "Hamam in der Katre Straße"},
  {value: "Hamam in der Zambeliou Straße"},
  {value: "Hammam in der Halidon Straße"},
  {value: "Haus des Goldschmieds Anikitos Garofalakis"},
  {value: "Haus des Kaufmanns Naxakis"},
  {value: "Haus des Petros Markantonakis (Erietta Hortatzi-Markantonaki)"},
  {value: "Haus von Fenareti Mitsotaki-Britisches Konsulat während des kretischen Staates"},
  {value: "Haus von Valerios Kaloutsis"},
  {value: "Hebrew Synagoge Etz Hayyim"},
  {value: "Historisches Archiv von Kreta"},
  {value: "Italienische Kaserne"},
  {value: "Justizverwaltungsgebäude"},
  {value: "Kathedrale der Darstellung der Jungfrau Maria (oder Trimartyri)"},
  {value: "Katholische Kirche"},
  {value: "Kavalier Priuli"},
  {value: "Kavalier von Agia Ekaterini"},
  {value: "Kirche Agia Ekaterini (Splantzia)"},
  {value: "Kirche Agia Irini"},
  {value: "Kirche Agioi Anargyroi"},
  {value: "Kirche Agios Eleftherios"},
  {value: "Kirche Agios Roccos"},
  {value: "Kirche der Agia Kyriaki"},
  {value: "Kirche der Agia Magdalini"},
  {value: "Kirche der Geburt der Jungfrau Maria"},
  {value: "Kirche des Agios Georgios Koubelis"},
  {value: "Kloster der Santa Maria die Miracolli"},
  {value: "Kloster des Agios Nikolaos der Dominikanermönche"},
  {value: "Kloster des Heiligen Franziskus der Franziskaner"},
  {value: "Kloster San Salvatore der Franziskaner"},
  {value: "Lando (oder Agios Dimitrios) Bastion"},
  {value: "Leuchtturm des Hafens Chania"},
  {value: "Minarett Ahmed Aga"},
  {value: "Mocenigo Bastion"},
  {value: "Moro Werfte"},
  {value: "Mündung des Flusses Kladisos"},
  {value: "Nationale Forschungsstiftung „Eleftherios Venizelos“"},
  {value: "Österreichisches Konsulat während des kretischen Staates"},
  {value: "Palast von Prinz Georgios"},
  {value: "Philologische Vereinigung „Chrysostomos“"},
  {value: "Platz 1866"},
  {value: "Platz Talo"},
  {value: "Residenz des englischen Handelsmarine-Kapitäns Soos"},
  {value: "Residenz - Museum des Eleftherios K. Venizelos"},
  {value: "Russische Kaserne"},
  {value: "Sabbionara Bastion"},
  {value: "Schulgebäude Korais"},
  {value: "Stadtgarten von Chania"},
  {value: "Stadtische Kunstgalerie"},
  {value: "Städtisches Kino „Kipos“ (Garten)"},
  {value: "Stivanadika"},
  {value: "Stockwerkerweiterung (Diavatiko) des venezianischen Herrenhauses Renier, Kapelle der Familie Renier"},
  {value: "Strand „Koum Kapi“"},
  {value: "Tabakaria"},
  {value: "Tabakaria (in Vivilaki Strasse)"},
  {value: "Theater „Dimitris Vlisidis“"},
  {value: "Tor Sabbionara"},
  {value: "Uhr des Stadtgartens"},
  {value: "Unterirdische Brunnen von Splantzia"},
  {value: "Venezianisches Gebäude"},
  {value: "Venezianisches Gebäude (ehemalige Residenz Stavroulakis)"},
  {value: "Venezianisches Gebäude (Fagotto)"},
  {value: "Venezianisches Gebäude (heute Hotel OFF)"},
  {value: "Venezianisches Gebäude (Hotel Alcanea)"},
  {value: "Venizelio-Konservatorium"},
  {value: "Werfte"},
  {value: "Yali Jamisi"},
  {value: "Zentrum für vielfältige Aktivitäten - Theater „Mikis Theodorakis“"},
  {value: "Αusgrabung “südlich der Mauern”"},
  {value: "Αusgrabung “Vakalunaki”"},
  {value: "Ηaus des Kyriakos Mitsotakis, Sohn des Konstantinos"},
  {value: "Ηerrenhaus Theodoros Manousakas"},
  {value: "Τheater des östlichen Grabens"},
  {value: "Route A: Außerhalb der Mauern"},
  {value: "Route C: Kultur-und Veranstaltungsplätze"},
  {value: "Route F: Festungen"},
  {value: "Route S: Küstenlinie-Natur"},
  
  
  
  
  
  
  
  
  {value: "Ancien Episkopeio (résidence de l’évêque)"},
  {value: "Ancienne résidence du capitaine anglais de la marine marchande Show"},
  {value: "Ancienne résidence du député Antonis Katzourakis (actuellement propriété de Klearchos Markantonakis)"},
  {value: "Ancienne résidence du député Charalampos Pologiorgis"},
  {value: "Ancienne résidence du député Konstantinos Foumis"},
  {value: "Ancienne résidence du directeur de banque Filippos Ntokos"},
  {value: "Ancienne résidence du négociant Nikolaos Koufakis"},
  {value: "Ancienne résidence du sénateur Mpistolakis (Bâtiment TEE, Section de Crète occidentale)"},
  {value: "Ancienne résidence Lantraki"},
  {value: "Archives historiques de Crète"},
  {value: "Bastion de Piatta Forma"},
  {value: "Bastion Lando (ou bastion d’Agios Dimitrios)"},
  {value: "Bastion Sabbionara"},
  {value: "Bastion San Salvatore"},
  {value: "Bastion Santa Lucia"},
  {value: "Bâtiment de la Banque de Grèce"},
  {value: "Bâtiment de l’ancienne Banque Agricole (Agrotiki Trapeza)"},
  {value: "Bâtiments scolaires Koraïs"},
  {value: "Bibliothèque municipale"},
  {value: "Caserne russe"},
  {value: "Cathédrale Eisodion tis Theotokou (Présentation de la Mère de Dieu) (ou Trimartyri)"},
  {value: "Centre culturel de Chania"},
  {value: "Centre d’architecture de la Méditerranée (Grand arsenal)"},
  {value: "Centre polyvalent - Théâtre « Mikis Théodorakis »"},
  {value: "Cimetière catholique de Chania (cimetière franc)"},
  {value: "Cinéma municipal « Kipos »"},
  {value: "Collection byzantine et post-byzantine de Chania"},
  {value: "Conservatoire Venizelio"},
  {value: "Consulat d’Allemagne en Crète Autonome"},
  {value: "Consulat d’Autriche en Crète Autonome"},
  {value: "Consulat de France en Crète Autonome"},
  {value: "Consulat de Grèce en Crète Autonome"},
  {value: "Côte Kum-Kapisi"},
  {value: "Couvent de Santa Maria dei Miracolli"},
  {value: "Couvent de Santa Maria della Misericordia des Augustines"},
  {value: "Defkalionos"},
  {value: "Despotiko de Chania"},
  {value: "École française"},
  {value: "Édifice Angela Xirouchaki"},
  {value: "Edifice du député et sénateur Manoussos Koundouros"},
  {value: "Édifice vénitien"},
  {value: "Édifice vénitien (actuel hôtel OFF)"},
  {value: "Édifice vénitien (ex-résidence Stavroulakis)"},
  {value: "Édifice vénitien (Fagotto)"},
  {value: "Édifice vénitien (hôtel Alcanea)"},
  {value: "Eglise Catholique"},
  {value: "Église d’Agia Aikaterini (Splantzia)"},
  {value: "Église d’Agia Irini"},
  {value: "Église d’Agia Kyriaki"},
  {value: "Église d’Agia Magdalini (Sainte-Madeleine)"},
  {value: "Église d’Agioi Anargyroi"},
  {value: "Église d’Agios Eleftherios"},
  {value: "Église d’Agios Frangiskos (saint François)"},
  {value: "Église d’Agios Georgios Koumpelis"},
  {value: "Église d’Agios Rokkos"},
  {value: "Église Gennesiou tis Theotokou (Naissance de la Mère de Dieu)"},
  {value: "Estuaire du cours d’eau de Kladissos"},
  {value: "Fontaine souterraine de Splantzia"},
  {value: "Fort de Firkas"},
  {value: "Fortifications"},
  {value: "Fouilles au «Sud des Remparts»"},
  {value: "Fouilles gréco-suédoises"},
  {value: "Fouilles « Lionaki-Vlamaki »"},
  {value: "Fouilles « Papadopoulos »"},
  {value: "Fouilles «Vakalounakis»"},
  {value: "Giali Tzami"},
  {value: "Gouvernorat de la Crète Autonome (« ancienne Division »)"},
  {value: "Hammam rue Katrè"},
  {value: "Institut national pour la recherche et les études “Eleftherios Venizélos”"},
  {value: "La caserne italienne"},
  {value: "La galerie voûtée (diavatiko) du palais vénitien Renier, la chapelle de la famille Renier"},
  {value: "Le bastion d’Agios Nikolaos de Molos"},
  {value: "Le bastion de Mocenigo"},
  {value: "Le hammam de la rue Chalidon"},
  {value: "Le hammam rue Zampeliou"},
  {value: "Le Jardin municipal"},
  {value: "Le minaret Ahmet aga"},
  {value: "Le palais du prince Georgios"},
  {value: "Le Phare au port de Chania"},
  {value: "Les fouilles « Mathioudakis »"},
  {value: "Les tombes des Venizélos"},
  {value: "Le « surbastion » d’Agia Aikaterini"},
  {value: "L’horloge du Jardin municipal"},
  {value: "Marché municipal"},
  {value: "Monastère d’Agios Nikolaos des dominicains"},
  {value: "Monastère de San Salvatore des Franciscains"},
  {value: "Monument aux morts de Nea Chora"},
  {value: "Musée archéologique de Chania"},
  {value: "Musée naval de Crète"},
  {value: "Neoria (bassins de carénage)"},
  {value: "Néoria (bassins de carénage) de Moro"},
  {value: "Palais de justice"},
  {value: "Petit port de Nea Chora"},
  {value: "Pinacothèque municipale"},
  {value: "Place Talo"},
  {value: "Plateia 1866"},
  {value: "Porte Sabbionara"},
  {value: "Propriété Eleni & Panagiotis Mpolakis"},
  {value: "Résidence Aikaterini Saridaki"},
  {value: "Résidence de Kyriakos Konstantinos Mitsotakis"},
  {value: "Résidence de l’orfèvre Anikitos Garofalakis"},
  {value: "Résidence de Petros Markantonakis (Henriette Chortatzi-Markantonaki)"},
  {value: "Résidence du négociant Naxakis"},
  {value: "Résidence Emmanouil Papagiannakis"},
  {value: "Résidence Fainareti Mitsotakis - Consulat britannique en Crète Autonome"},
  {value: "Résidence Georgios Panigyrakis"},
  {value: "Résidence Georgios Papadopetrou"},
  {value: "Résidence Konstaninos Katzourakis"},
  {value: "Résidence - Musée 'Eleftherios K. Venizélos'"},
  {value: "Résidence Panagiotis Mouzourakis"},
  {value: "Résidences Chatzidaki & Papadonikolaki"},
  {value: "Résidence Théodoros Manoussakas"},
  {value: "Résidence Valerios Kaloutsis"},
  {value: "Société historique, folklorique et archéologique de Crète (ILAEK)"},
  {value: "Société philologique « O Chrysostomos »"},
  {value: "Stivanadika"},
  {value: "Surbastion de Priuli"},
  {value: "Synagogue Etz Hayyim"},
  {value: "Tabakaria"},
  {value: "Tabakaria (rue Vivilaki)"},
  {value: "Théâtre « Dimitris Vlissidis »"},
  {value: "Théâtre du Fossé oriental"},
  {value: "Tour génoise"},
  {value: "Itinéraire A: Hors des murs"},
  {value: "Itinéraire C: Sites de culture et d’évènements"},
  {value: "Itinéraire F: Fortifications"},
  {value: "Itinéraire S: Côte - Nature"},
  
  
  
  
  
  
  
  
  {value: "Августинский Монастырь Санта Мария делла Мизерикордиа"},
  {value: "Австрийское консульство в Критском государстве"},
  {value: "Администрация Критского государства («Старая дивизия»)"},
  {value: "Археологический музей Ханьи"},
  {value: "Бастион Ландо (или Святого Димитрия)"},
  {value: "Бастион Мочениго"},
  {value: "Бастион Пьятта Форма"},
  {value: "Бастион Саббьонара"},
  {value: "Бастион Сан Сальваторе"},
  {value: "Бастион Санта Лючия"},
  {value: "Бастион Святого Николая у мола"},
  {value: "Бывший дом депутата Антониоса Катзуракиса (здание, принадлежащее Клеархосу Маркантонакису)"},
  {value: "Бывший дом депутата Константиноса Фумиса"},
  {value: "Бывший дом депутата Хараламбоса Пологиоргиса"},
  {value: "Бывший дом Директора Банка Филип Докоса"},
  {value: "Бывший дом купца Николаоса Куфакиса"},
  {value: "Бывший дом Лантракиса"},
  {value: "Бывший дом сенатора Бистолакиса (нынешнее здание Технической Палаты Греции, Отделение Западного Крита)"},
  {value: "Венецианское здание"},
  {value: "Венецианское здание («Fagotto»)"},
  {value: "Венецианское здание (бывший дом Ставрулакиса)"},
  {value: "Венецианское здание (гостиница «Alcanea»)"},
  {value: "Венецианское здание (ныне гостиница «OFF»)"},
  {value: "Верфи Моро"},
  {value: "Верфи (Неория)"},
  {value: "Византийская и Поствизантийская коллекция Ханьи"},
  {value: "Военно-морской музей Крита"},
  {value: "Ворота Саббьонара"},
  {value: "Гавань в Неа Хора"},
  {value: "Галерея венецианского дворца Ренье, часовня семьи Ренье"},
  {value: "Генуэзская башня (Генуатикос)"},
  {value: "Греческое консульство в Критском государстве"},
  {value: "Греческо-шведские раскопки"},
  {value: "Дворец Принца Георга"},
  {value: "Дворец Теодороса Манусакаса"},
  {value: "Девкалион"},
  {value: "Дом английского судоторговца Шоу"},
  {value: "Дом Валериоса Калутсиса"},
  {value: "Дом Георгиоса Панигиракиса"},
  {value: "Доминиканский монастырь Св. Николая"},
  {value: "Дом Кирьякоса Константиновича Митсотакиса"},
  {value: "Дом купца Наксакиса"},
  {value: "Дом Петроса Маркантонакиса (Генриетты Хортатзи-Маркантонаки)"},
  {value: "Дом Фенарети Митсотаки – Британское консульство в Критском государстве"},
  {value: "Дом Хатзидакиса и Папаниколакиса"},
  {value: "Дом ювелира Аникитоса Гарофилакиса"},
  {value: "Духовный центр Ханьи"},
  {value: "Еврейская синагога Эдж Хагим"},
  {value: "Здание Банка Греции"},
  {value: "Здание бывшего Сельскохозяйственного Банка"},
  {value: "Здание Георгиоса Пападопетроса"},
  {value: "Здание депутата и сенатора Манусоса Кундуроса"},
  {value: "Здание Екатерины Саридаки"},
  {value: "Здание Константиноса Катзуракиса"},
  {value: "Здание Панагиотиса Музуракиса"},
  {value: "Здание Элени и Панагиотиса Болаки"},
  {value: "Здание Эммануила Папагианнакиса"},
  {value: "Здние Ангелы Ксирухаки"},
  {value: "Исторический архив Крита"},
  {value: "Историческое, фольклорное и археологическое общество Крита (ИЛАЕК)"},
  {value: "Итальянская казарма"},
  {value: "Католическая церковь"},
  {value: "Католическое кладбище Ханьи (Франкское кладбище)"},
  {value: "Кафедральный собор Введения во храм Богородицы (или Троемученицы)"},
  {value: "Консерватория «Венизелио»"},
  {value: "Крепость Фирка"},
  {value: "Маяк в порту Ханьи"},
  {value: "Мемориал павшим жителям Неа Хора"},
  {value: "Мечеть Гьяли"},
  {value: "Минарет Ахмет Ага"},
  {value: "Митрополичья палата Ханьи"},
  {value: "Многофункциональный центр - Театр «Микис Теодоракис»"},
  {value: "Могилы Венизелосов"},
  {value: "Монастырь Санта Мария деи Мираколли"},
  {value: "Муниципальная художественная галерея"},
  {value: "Муниципальный кинотеатр «Кипос»"},
  {value: "Муниципальный рынок"},
  {value: "Муниципальный сад"},
  {value: "Набережная «Кум Капи»"},
  {value: "Национальный исследовательский фонд «Элефтериос Венизелос»"},
  {value: "Немецкое консульство в Критском государстве"},
  {value: "Площадь 1866 г."},
  {value: "Площадь Тало"},
  {value: "Подземный фонтан для омовения Сплантзья"},
  {value: "Публичная библиотека"},
  {value: "Равелин Приули"},
  {value: "Равелин Святой Екатерины"},
  {value: "Раскопки «Вакалунаки»"},
  {value: "Раскопки «К югу от стены»"},
  {value: "Раскопки «Лионаки-Вламаки»"},
  {value: "Раскопки «Матиудаки»"},
  {value: "Раскопки «Пападопулоса»"},
  {value: "Резиденция - музей Элефтериоса К. Венизелоса"},
  {value: "Русские казармы"},
  {value: "Собор Святого Георгия Кумпелиса"},
  {value: "Собор Святого Элевтерия"},
  {value: "Собор Святой Екатерины (Сплантзья)"},
  {value: "Собор Святой Ирины"},
  {value: "Собор Святой Кириакии"},
  {value: "Собор Святой Магдалины"},
  {value: "Собор Святых Бессребреников"},
  {value: "Старый Епископский дворец"},
  {value: "Стиванадика"},
  {value: "Судебно-административный дворец"},
  {value: "Табакарья"},
  {value: "Табакарья (на ул. Вивилаки)"},
  {value: "Театр восточного рва"},
  {value: "Театр «Димитрис Влисидис»"},
  {value: "Устье реки Кладисос"},
  {value: "Филологическая ассоциация «Хрисостом»"},
  {value: "Фортификационные сооружения"},
  {value: "Францисканский монастырь Сан Сальваторе"},
  {value: "Францисканский Монастырь Святого Франциска"},
  {value: "Французская школа"},
  {value: "Французское консульство в Критском государстве"},
  {value: "Хамам на ул. Зампелиу"},
  {value: "Хамам на ул. Катре"},
  {value: "Хамам на ул. Халидон"},
  {value: "Центр Архитектуры Средиземноморья (Большой арсенал)"},
  {value: "Церковь Рождества Пресвятой Богородицы"},
  {value: "Церковь Св. Роха"},
  {value: "Часы в Муниципальном саду"},
  {value: "Школьные здания на ул. Кораи"},
  {value: "Маршрут A: За пределами стен"},
  {value: "Маршрут C: Объекты культуры и мероприятий"},
  {value: "Маршрут F: Фортификационные сооружения"},
  {value: "Маршрут S: Береговая линия-Природа"},
  

]