import React from 'react'
import { Row, Col } from 'antd'
import classes from "./styles.module.css"
import { useTranslation } from "react-i18next"
import HelmetComponent from '../../components/Helmet'
import TitleAndSubtitleAndBtn from '../../components/PageComponents/TitleAndSubtitleAndBtn'
import ReturnAndHomeBtn from '../../components/PageComponents/ReturnAndHomeBtn'
import ComponentBtn from './ComponentBtn'


const MunicipalSocialNetworks: React.FC = () => {

  const { t } = useTranslation()

  return (
    <>
      <HelmetComponent title='Κοινωνικά Δίκτυα' descrition='Κοινωνικά Δίκτυα' />
      
      <TitleAndSubtitleAndBtn title={t('Κοινωνικά Δίκτυα')} subTitle={''}/>

      
      <Row justify="center">

        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span:8, offset: 2 }}>

          <div className={classes.MunicipalityContainerRight}>
            <ComponentBtn
              image='img/Municipality/chaniwn.jpg'
              Title={t('Δήμος Χανίων')}
              FacebookLink="https://www.facebook.com/Chania.tourism"
              InstagramLink="https://www.instagram.com/chaniatourism/"
              YoutubeLink="https://youtube.com/playlist?list=PLrxLhQmW8aqFbSKoPvf1e5BeS1B5pFsi_"
            />
          </div>
        </Col>
      </Row>

      <ReturnAndHomeBtn />

    </>
  )
}

export default MunicipalSocialNetworks
