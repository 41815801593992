import React from "react"
import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import classes from "./Image.module.css"


function Image() {

  return (
    <>
    <div className={classes.footerContainerBg}>
        <div className={classes.footerContainer}>
          
        <Row justify="center">
          <Col>
            <img className={classes.image} src="../img/image.png" alt="Image"  />
          </Col>
        </Row>
        

      </div>
    </div>
    </>
  )
}

export default Image