import { configureStore } from '@reduxjs/toolkit'

import Category from './mapPage/category'
import Event from './mapPage/event'
import Route from './mapPage/route'
import Search from './mapPage/search'
import RoutesArr from './mapPage/routesArray'
import RoutesArr2 from './mapPage/routesArray'
import PoisesArrayForSearch from './mapPage/routesArray'



export const store = configureStore({
  reducer: {
    Categories: Category,
    Events: Event,
    Routes: Route,
    Searches: Search,
    RoutesArr: RoutesArr,
    RoutesArr2: RoutesArr2,
    PoisesArrayForSearch: PoisesArrayForSearch
  },
})

export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch