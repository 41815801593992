
import React from "react"
import Props from './svgProps'

const EuroEventSvg = ({width, height, fill}:Props) => {

    return (
    <svg width="22" height="24" viewBox="0 0 28 18" fill="none" xmlns="http://www.w3.org/2000/svg"> 
        <path d="M19.5938 3.51903C18.1113 2.25973 16.1913 1.5 14.0938 1.5C9.39933 1.5 5.59375 5.30558 5.59375 10C5.59375 14.6944 9.39933 18.5 14.0938 18.5C16.1913 18.5 18.1113 17.7403 19.5938 16.481M3.59375 12H13.5938M3.59375 8H13.5938" stroke="#63584F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
    )
}

export default EuroEventSvg