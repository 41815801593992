import React, { useState, useRef } from 'react'
import { Col, Row, Button, Rate, Popconfirm } from 'antd'
import classes from "./PoisRoute.module.css"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../../utils/auth"
import { HeartOutlined, PlusCircleOutlined, CloseCircleOutlined, ExclamationOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import LoginOrRegisterModal from './LoginOrRegisterModal'
import GlobusSvg from '../../img/svg/globus'
import MapSvg from '../../img/svg/map'

import { ChangeMunicipality } from './ChangeMunicipality'
interface Props{
  id: string
  image: string
  title: string
  rate: number
  text: any
  municipality: string
  category: string
}

const PoisComponent: React.FC<Props> = ({id, image, title, rate, text, municipality, category }:Props) => {

  const { t } = useTranslation()
  const navigate = useNavigate()
  const auth = useAuth()
  const Municipality = ChangeMunicipality(municipality)

  const [size, setSize] = useState<any>({});
  const imgRef = useRef<any>(null)


  return (
  <>
      <div className={classes.PointContainer} >
      <Row >

        <Col xs={24} sm={24} md={5} lg={5} >
          <Link to={`/route/${id}`} >
              <div>
                <img
                  ref={imgRef}
                  onLoad={ (e: any ) => {
                          setSize({
                            height: e.target.naturalHeight,
                            width: e.target.naturalWidth
                          })
                  }}
                  src={image !== '' ? image : '../img/defaultPoisImage.jpg'}
                  className={ size.height > size.width ? classes.PointImgPortrait : classes.PointImg}
                  alt={title} />
              </div>
          </Link>
        </Col>

        <Col xs={24} sm={24} md={10} lg={13}>

          <div className={classes.PointCenter}>
            <div className={classes.PointCenterLeft} onClick={() => navigate(`/route/${id}`, { replace: false  })}>
              {title}
            </div>
          </div>

          <div className={classes.PointCenter}>
              {/* <p className={classes.PointCenterCenterP}>{text}...</p> */}
            <div className={classes.PointCenterCenterP} dangerouslySetInnerHTML={{ __html: text }}/>
          </div>

        </Col>

        <Col xs={24} sm={24} md={9} lg={6}>
            <div style={{color: '#897563'}}>
             
            <Button
              size='large'
              icon={<PlusCircleOutlined />}
              className={classes.PointMoreBtn}
              onClick={() => navigate(`/route/${id}`)}>
              {t('Περισσότερα')}
            </Button> 
              
            <Button
              size='large'
              icon={<MapSvg width={25} height={25} fill="#897563" />}
              className={classes.MapBtn}
                href={`/img/maps/${id}.pdf`} target='_blank' download>
                <span className={classes.BtnMapText }>{t('Χάρτης Διαδρομής (PDF)')}</span>
            </Button> 
          </div>
        </Col>

      </Row>
      </div>
    </>
  )
}

export default PoisComponent
