export const AccessibilityStatementData = [{
'gr':{
  id: 1,
  title: '',
  text:`
<h3>Δήλωση προσβασιμότητας</h3>
Ο Δήμος Χανιών δεσμεύεται να καθιστά τον ιστότοπο <a href="https://www.chaniaroutes.gr" target="_blank" style="color: rgb(10, 85, 114);
text-decoration: underline;">https://chaniaroutes.gr</a> προσβάσιμο, σύμφωνα με την οδηγία (Ευρωπαϊκή Ένωση) 2016/2102 του Ευρωπαϊκού Κοινοβουλίου και του Συμβουλίου.
Η παρούσα δήλωση προσβασιμότητας εφαρμόζεται στον ιστότοπο <a href="https://www.chaniaroutes.gr" target="_blank" style="color: rgb(10, 85, 114);
text-decoration: underline;">https://chaniaroutes.gr</a>


<h3>Κατάσταση συμμόρφωσης</h3>

Ο ιστότοπος <a href="https://www.chaniaroutes.gr" target="_blank" style="color: rgb(10, 85, 114);text-decoration: underline;">https://chaniaroutes.gr</a> 
συνάδει με τις οδηγίες για την προσβασιμότητα στο περιεχόμενο Ιστού (WCAG 2.1 ΑΑ).

<h3>Κατάρτιση της παρούσας δήλωσης προσβασιμότητας</h3>
Η παρούσα δήλωση καταρτίστηκε στις 09-05-2023.
Η αξιολόγηση της συμμόρφωσης του ιστοτόπου προς τις απαιτήσεις της Οδηγίας (ΕΕ) 2016/2102 πραγματοποιήθηκε απο την ανάδοχο εταιρεία υλοποίησης του έργου, Dotsoft Α.Ε., με τη χρήση του WAVE Website Accessibility Evaluation Tool.




<h3>Υποβολή παρατηρήσεων και στοιχεία επικοινωνίας</h3>
<div>
Σύμφωνα με τις προβλέψεις του ν. 4727/2020 (ΦΕΚ 184 Α’ 23-09-2020), άρθρο 45, κάθε ενδιαφερόμενος έχει δικαίωμα να υποβάλλει παρατηρήσεις ή αίτηση για ενημέρωση ως προς την κατάσταση συμμόρφωσης ή αίτηση για πληροφορίες που ελλείπουν.  </div>
<div>Τα ανωτέρα μπορούν να αποσταλούν στην ηλεκτρονική διεύθυνση e-mail: chaniaroutes @ chania.gr
</div>


<h3>Διαδικασία εκτέλεσης</h3>
Η αρμόδια διεύθυνση του Δήμου Χανιών απαντά στον ενδιαφερόμενο με πρόσφορο τρόπο. Σε περίπτωση μη ικανοποιητικής απάντησης, ο ενδιαφερόμενος μπορεί να αποταθεί 
στον Συνήγορο του Πολίτη <a href="https://www.synigoros.gr" target="_blank" style="color: rgb(10, 85, 114);text-decoration: underline;">(https://www.synigoros.gr)</a>.


<h3>Μενού Προσβασιμότητας</h3>
<div>
Το μενού προσβασιμότητας του ιστοτόπου <a href="https://chaniaroutes.gr" target="_blank" style="color: rgb(10, 85, 114);text-decoration: underline;">https://chaniaroutes.gr</a> ενεργοποιείται κάνοντας κλικ στο εικονίδιο του μενού προσβασιμότητας που εμφανίζεται στην κάτω δεξιά γωνία της σελίδας. Αφού ενεργοποιήσετε το μενού προσβασιμότητας, περιμένετε λίγο για να φορτωθεί στο σύνολό του.
</div>
<div>
Γίνεται συνεχής προσπάθεια από το Δήμο Χανιών για βελτίωση και αποκατάσταση προβλημάτων προσβασιμότητας, με τακτικές σαρώσεις στο 
<a href="https://chaniaroutes.gr" target="_blank" style="color: rgb(10, 85, 114);text-decoration: underline;">https://chaniaroutes.gr</a> με τον σαρωτή προσβασιμότητας του WAVE.
</div>
<div>
Ωστόσο, παρά την επιθυμία μας να κάνουμε όλες τις σελίδες και το περιεχόμενο του 
<a href="https://chaniaroutes.gr" target="_blank" style="color: rgb(10, 85, 114);text-decoration: underline;">https://chaniaroutes.gr</a> πλήρως προσβάσιμο, 
κάποιο περιεχόμενο ενδέχεται να μην έχει ακόμη προσαρμοστεί πλήρως στα αυστηρότερα πρότυπα προσβασιμότητας.
</div>
`
},
  
'en': {
  id: 2,
  title: 'Accessibility Statement',
  text:`
  <h3>Accessibility Statement</h3>
  The Municipality of Chania undertakes to make the website <a href="https://www.chaniaroutes.gr" target="_blank" style="color: rgb(10, 85, 114);
  text-decoration: underline;">https://chaniaroutes.gr</a> accessible, in accordance with Directive (European Union) 2016/2102 of the European Parliament and of the Council.
  This accessibility statement applies to the website <a href="https://www.chaniaroutes.gr" target="_blank" style="color: rgb(10, 85, 114);
  text-decoration: underline;">https://chaniaroutes.gr</a>
  
  
  <h3>Compliance Status</h3>
  
  The website <a href="https://www.chaniaroutes.gr" target="_blank" style="color: rgb(10, 85, 114); text-decoration: underline;">https://chaniaroutes. gr</a>
  complies with the Web Content Accessibility Guidelines (WCAG 2.1 AA).
  
  <h3>Preparation of this accessibility statement</h3>
  This statement was drawn up on 09-05-2023.
  The assessment of the website's compliance with the requirements of Directive (EU) 2016/2102 was carried out by the contractor implementing the project, Dotsoft S.A., with the use of WAVE Website Accessibility Evaluation Tool.
  
  <h3>Submit comments and contact information</h3>
  <div>
  According to the provisions of Law 4727/2020 (Government Gazette 184 A' 23-09-2020), article 45, every interested party has the right to submit observations or request for information regarding the compliance status or request for missing information. </div>
  <div>The above can be sent to the e-mail address: chaniaroutes @ chania.gr
  </div>
  
  
  <h3>Execution process</h3>
  The competent department of the Municipality of Chania responds to the interested party in an appropriate manner. In the event of an unsatisfactory answer, the interested party may withdraw
  to the Ombudsman <a href="https://www.synigoros.gr" target="_blank" style="color: rgb(10, 85, 114); text-decoration: underline;">(https:/ /www.synigoros.gr)</a>.
  
  
  <h3>Accessibility Menu</h3>
  <div>
  The accessibility menu of the website <a href="https://chaniaroutes.gr" target="_blank" style="color: rgb(10, 85, 114); text-decoration: underline;">https://chaniaroutes .gr</a> is activated by clicking on the accessibility menu icon that appears in the lower right corner of the page. After enabling the accessibility menu, wait a while for it to load in its entirety.
  </div>
  <div>
  A continuous effort is made by the Municipality of Chania to improve and restore accessibility problems, with regular scans in
  <a href="https://chaniaroutes.gr" target="_blank" style="color: rgb(10, 85, 114); text-decoration: underline;">https://chaniaroutes.gr</a > with WAVE's accessibility scanner.
  </div>
  <div>
  However, despite our desire to make all of its pages and content
  <a href="https://chaniaroutes.gr" target="_blank" style="color: rgb(10, 85, 114); text-decoration: underline;">https://chaniaroutes.gr</a > fully accessible,
  some content may not yet be fully adapted to the strictest accessibility standards.
  </div>
  `
},

'fr':{
  id: 3,
  title: `Déclaration d'accessibilité`, 
  text:`
  <h3>Déclaration d'accessibilité</h3>
La municipalité de La Canée s'engage à rendre le site Web <a href="https://www.chaniaroutes.gr" target="_blank" style="color: rgb(10, 85, 114); text-decoration: underline;">https://chaniaroutes.gr</a> accessible, conformément à la directive (Union européenne) 2016/2102 du Parlement européen et du Conseil.
Cette déclaration d'accessibilité s'applique au site Web <a href="https://www.chaniaroutes.gr" target="_blank" style="color: rgb(10, 85, 114);
text-decoration: underline;">https://chaniaroutes.gr</a>


<h3>État de conformité</h3>

Le site Web <a href="https://www.chaniaroutes.gr" target="_blank" style="color: rgb(10, 85, 114); text-decoration: underline;">https://chaniaroutes. gr</a>
est conforme aux directives pour l'accessibilité des contenus Web (WCAG 2.1 AA).


<h3>Préparation de cette déclaration d'accessibilité</h3>
Cette déclaration a été établie le 05-09-2023.
L'évaluation de la conformité du site Web aux exigences de la directive (UE) 2016/2102 a été réalisée par le contractant chargé de la mise en œuvre du projet, Dotsoft S.A., avec l'utilisation du site Web WAVE Outil d'évaluation de l'accessibilité.


<h3>Soumettre des commentaires et des informations de contact</h3>
<div>
Conformément aux dispositions de la loi 4727/2020 (Journal officiel 184 A' 23-09-2020), article 45, toute partie intéressée a le droit de soumettre des observations ou de demander des informations concernant l'état de conformité ou de demander des informations manquantes. </div>
<div>Ce qui précède peut être envoyé à l'adresse e-mail: chaniaroutes @ chania.gr
</div>


<h3>Processus d'exécution</h3>
Le service compétent de la municipalité de La Canée répond à l'intéressé de manière appropriée. En cas de réponse insatisfaisante, l'intéressé peut retirer
au Médiateur <a href="https://www.synigoros.gr" target="_blank" style="color: rgb(10, 85, 114); text-decoration: underline;">(https:/ / www.synigoros.gr)</a>.


<h3>Menu Accessibilité</h3>
<div>
Le menu d'accessibilité du site Web <a href="https://chaniaroutes.gr" target="_blank" style="color: rgb(10, 85, 114); text-decoration: underline;">https:// chaniaroutes .gr</a> est activé en cliquant sur l'icône du menu d'accessibilité qui apparaît dans le coin inférieur droite de la page. Après avoir activé le menu d'accessibilité, attendez un moment pour qu'il se charge dans son intégralité.
</div>
<div>
Un effort continu est fait par la municipalité de La Canée pour améliorer et résoudre les problèmes d'accessibilité, avec des analyses régulières dans
<a href="https://chaniaroutes.gr" target="_blank" style="color: rgb(10, 85, 114); text-decoration: underline;">https://chaniaroutes.gr</a > avec le scanner d'accessibilité de WAVE.
</div>
<div>
Cependant, malgré notre volonté de rendre l'ensemble de ses pages et de son contenu
<a href="https://chaniaroutes.gr" target="_blank" style="color: rgb(10, 85, 114); text-decoration: underline;">https://chaniaroutes.gr</a > entièrement accessible,
certains contenus peuvent ne pas encore être totalement adaptés aux normes d'accessibilité les plus strictes.
</div>
  `
  },

  'de':{
    id: 4,
    title: 'Erklärung zur Barrierefreiheit', 
    text:`
<h3>Erklärung zur Barrierefreiheit</h3>
Die Gemeinde Chania verpflichtet sich, die Website <a href="https://www.chaniaroutes.gr" target="_blank" style="color: rgb(10, 85, 114); text-decoration: underline;">https://chaniaroutes.gr</a> zugänglich, gemäß der Richtlinie (Europäische Union) 2016/2102 des Europäischen Parlaments und des Rates.
Diese Erklärung zur Barrierefreiheit gilt für die Website <a href="https://www.chaniaroutes.gr" target="_blank" style="color: rgb(10, 85, 114); text-decoration: underline;">https://chaniaroutes.gr</a>


<h3>Konformitätsstatus</h3>

Die Website <a href="https://www.chaniaroutes.gr" target="_blank" style="color: rgb(10, 85, 114); text-decoration: underline;">https://chaniaroutes. gr</a>
entspricht den Web Content Accessibility Guidelines (WCAG 2.1 AA).


<h3>Vorbereitung dieser Erklärung zur Barrierefreiheit</h3>
Diese Erklärung wurde am 09.05.2023 erstellt.
Die Bewertung der Konformität der Website mit den Anforderungen der Richtlinie (EU) 2016/2102 wurde vom Auftragnehmer, der das Projekt durchführt, Dotsoft S.A., und unter Verwendung der WAVE-Website durchgeführt Tool zur Bewertung der Barrierefreiheit.


<h3>Senden Sie Kommentare und Kontaktinformationen</h3>
<div>
Gemäß den Bestimmungen des Gesetzes 4727/2020 (Regierungsanzeiger 184 A vom 23.09.2020), Artikel 45, hat jede interessierte Partei das Recht, Anmerkungen zu machen oder Informationen zum Konformitätsstatus anzufordern oder fehlende Informationen anzufordern.  </div>
<div>Das Obige kann an die E-Mail-Adresse chaniaroutes @ chania.gr gesendet werden
</div>


<h3>Ausführungsprozess</h3>
Die zuständige Abteilung der Gemeinde Chania reagiert auf den Interessenten in angemessener Weise. Im Falle einer unbefriedigenden Antwort kann der Interessent zurücktreten
an den Ombudsmann <a href="https://www.synigoros.gr" target="_blank" style="color: rgb(10, 85, 114); text-decoration: underline;">(https:/ / www.synigoros.gr)</a>.


<h3>Barrierefreiheitsmenü</h3>
<div>
Das Barrierefreiheitsmenü der Website <a href="https://chaniaroutes.gr" target="_blank" style="color: rgb(10, 85, 114); text-decoration: underline;">https:// chaniaroutes .gr</a> wird durch Klicken auf das Barrierefreiheitsmenüsymbol aktiviert, das in der unteren Rechts Ecke der Seite angezeigt wird. Warten Sie nach dem Aktivieren des Barrierefreiheitsmenüs eine Weile, bis es vollständig geladen ist.
</div>
<div>
Die Gemeinde Chania unternimmt kontinuierliche Anstrengungen, um Barrierefreiheitsprobleme durch regelmäßige Scans zu verbessern und zu beheben
<a href="https://chaniaroutes.gr" target="_blank" style="color: rgb(10, 85, 114); text-decoration: underline;">https://chaniaroutes.gr</a > mit dem Barrierefreiheitsscanner von WAVE.
</div>
<div>
Trotz unseres Wunsches, alle seine Seiten und Inhalte zu erstellen
<a href="https://chaniaroutes.gr" target="_blank" style="color: rgb(10, 85, 114); text-decoration: underline;">https://chaniaroutes.gr</a > voll barrierefrei,
Einige Inhalte sind möglicherweise noch nicht vollständig an die strengsten Barrierefreiheitsstandards angepasst.
</div>
    `
  },
  
'ru':{
  id: 5,
  title: 'Заявление о доступности', 
  text:`
  <h3>Заявление о доступности</h3>
  Муниципалитет Ханьи обязуется сделать веб-сайт <a href="https://www.chaniaroutes.gr" target="_blank" style="color: rgb(10, 85, 114); text-decoration: underline;">https://chaniaroutes.gr</a> доступен в соответствии с Директивой (Европейский Союз) 2016/2102 Европейского парламента и Совета.
  Это заявление о доступности относится к веб-сайту <a href="https://www.chaniaroutes.gr" target="_blank" style="color: rgb(10, 85, 114); text-decoration: underline;">https://chaniaroutes.gr</a>
  
  
  <h3>Статус соответствия</h3>
  
  Сайт <a href="https://www.chaniaroutes.gr" target="_blank" style="color: rgb(10, 85, 114); text-decoration: underline;">https://chaniaroutes.gr</a>
  соответствует Руководству по доступности веб-контента (WCAG 2.1 AA).
  
  <h3>Подготовка данного заявления о доступности</h3>
  Акт составлен 05.09.2023.
  Оценка соответствия веб-сайта требованиям Директивы (ЕС) 2016/2102 была проведена подрядчиком, реализующим проект, Dotsoft S.A., с использованием веб-сайта WAVE. Инструмент оценки доступности.

  <h3>Отправить комментарии и контактную информацию</h3>
  <div>
  В соответствии с положениями статьи 45 Закона 4727/2020 (Правительственная газета 184 A' 23-09-2020) каждая заинтересованная сторона имеет право представить замечания или запросить информацию о статусе соблюдения или запросить недостающую информацию. </div>
  <div>Вышеупомянутое можно отправить на адрес электронной почты: info@explochania.gr
  </div>
  
  
  <h3>Процесс выполнения</h3>
  Компетентный отдел муниципалитета Ханьи отвечает заинтересованной стороне надлежащим образом. В случае неудовлетворительного ответа заинтересованная сторона может отказаться
  Омбудсмену <a href="https://www.synigoros.gr" target="_blank" style="color: rgb(10, 85, 114); text-decoration: underline;">(https:/ / www.synigoros.gr)</a>.
  
  
  <h3>Меню специальных возможностей</h3>
  <div>
  Меню специальных возможностей веб-сайта <a href="https://chaniaroutes.gr" target="_blank" style="color: rgb(10, 85, 114); text-decoration: underline;">https:// chaniaroutes .gr</a> активируется нажатием на значок меню специальных возможностей, который появляется в правом нижнем углу страницы. После включения меню специальных возможностей подождите некоторое время, пока оно загрузится полностью.
  </div>
  <div>
  Муниципалитет Ханьи прилагает постоянные усилия для улучшения и устранения проблем с доступностью, проводя регулярные проверки в
  <a href="https://chaniaroutes.gr" target="_blank" style="color: rgb(10, 85, 114); text-decoration: underline;">https://chaniaroutes.gr</a> со сканером специальных возможностей WAVE.
  </div>
  <div>
  Однако, несмотря на наше желание сделать все его страницы и контент
  <a href="https://chaniaroutes.gr" target="_blank" style="color: rgb(10, 85, 114); text-decoration: underline;">https://chaniaroutes.gr</a> полностью доступный,
  некоторый контент может быть еще не полностью адаптирован к самым строгим стандартам доступности.
  </div>
  `
  },

}
]
