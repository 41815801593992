import React from 'react'
import classes from "./styles.module.css"

interface Props{
  codesPerRoute: any
}

const TypesOfRoute: React.FC<Props> = ({ codesPerRoute }: any) => {

  let color: any = ['#97CC27', '#97473F', '#807357', '#d56e20', '#23CEFD' ]
  let code = ''
  let setColor : any, setColor2: any = '', setColor3: any = ''


  const ViewTypeOfRoute = () => {
    
    if(codesPerRoute.length == 11) {

      code = codesPerRoute.split(',')

      switch (code[0][0]) {
        case 'A': setColor = color[0]; break;
        case 'C': setColor = color[1]; break;
        case 'F': setColor = color[2]; break;
        case 'H': setColor = color[3]; break;
        case 'S': setColor = color[4]; break;
        default: break;
      }

      switch (code[1][0]) {
        case 'A': setColor2 = color[0]; break;
        case 'C': setColor2 = color[1]; break;
        case 'F': setColor2 = color[2]; break;
        case 'H': setColor2 = color[3]; break;
        case 'S': setColor2 = color[4]; break;
        default: break;
      }

      switch (code[2][0]) {
        case 'A': setColor3 = color[0]; break;
        case 'C': setColor3 = color[1]; break;
        case 'F': setColor3 = color[2]; break;
        case 'H': setColor3 = color[3]; break;
        case 'S': setColor3 = color[4]; break;
        default: break;
      }


      return (
        <>
          <div className={classes.typeDiv} id='poi'>
            <span className={classes.type} style={{ backgroundColor: setColor }}> {code[0]}</span>
            <span className={classes.type} style={{ backgroundColor: setColor2 }}> {code[1]}</span>
            <span className={classes.type} style={{ backgroundColor: setColor3 }}> {code[2]}</span>
          </div>
        </>
      )
    }



    else if (codesPerRoute.length == 7) {

      code = codesPerRoute.split(',')
      switch (code[0][0]) {
        case 'A': setColor = color[0]; break;
        case 'C': setColor = color[1]; break;
        case 'F': setColor = color[2]; break;
        case 'H': setColor = color[3]; break;
        case 'S': setColor = color[4]; break;
        default: break;
      }

      switch (code[1][0]) {
        case 'A': setColor2 = color[0]; break;
        case 'C': setColor2 = color[1]; break;
        case 'F': setColor2 = color[2]; break;
        case 'H': setColor2 = color[3]; break;
        case 'S': setColor2 = color[4]; break;
        default: break;
      }

      return (
        <>
          <div className={classes.typeDiv} id='poi'>
            <span className={classes.type} style={{ backgroundColor: setColor }}> {code[0]}</span>
            <span className={classes.type} style={{ backgroundColor: setColor2 }}> {code[1]}</span>
          </div>
        </>
      )
    }


    else{

      switch (codesPerRoute[0]) {
        case 'A': setColor = color[0]; break;
        case 'C': setColor = color[1]; break;
        case 'F': setColor = color[2]; break;
        case 'H': setColor = color[3]; break;
        case 'S': setColor = color[4]; break;
        default: break;
      }

      return (
        <>
          <div className={classes.typeDiv} id='poi'>
            <span className={classes.type} style={{ backgroundColor: setColor }}> {codesPerRoute}</span>
          </div>
        </>
      )
    }

  }

  return (
    <ViewTypeOfRoute />
  )
}

export default TypesOfRoute
