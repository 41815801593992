import React from "react"
import classes from "./Footer.module.css"



import Mobile from './parts/Mobile'
import Image from './parts/Image'
import Download from './parts/Download'


function Footer() {

  return (
    <>
      <Mobile />
      <Image />
      <Download />
    </>
  )
}

export default Footer