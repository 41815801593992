
import React, {useState, Fragment} from "react"
import { Col, Row, Carousel} from 'antd'
import { useTranslation } from "react-i18next"
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import PoisComponent from '../../../components/Box/PoisRouteComponent'
import classes from "../../../components/Box/PoisRoute.module.css"
import { settingsCarouselEvents } from '../../../components/ArrowsForCarousel/Categories'
import i18n from '../../../../src/translation'

interface Props{
  lg: number
  xs: number
  paginationNumber: number
  array: any
  total: number
  setPoisesMarkerExploration: any
  isAuth?: boolean
  isAddOrDelete?: boolean
  onClickAddRemove :(idPois : string) => void,
}

const ViewPointsComponent: React.FC<Props> = ({ lg, xs, paginationNumber, array, total, setPoisesMarkerExploration, isAuth, onClickAddRemove, isAddOrDelete }:Props ) => {


  const [Poises, setPoises] = useState<any[]>(array);
  const [pageSize, setPageSize] = useState<number>(8);



  return (
    <>
      <BrowserView>
        <Row justify="center">
          <Col xs={{ span: xs }} sm={{ span: xs }} md={{ span: lg }} lg={{ span: lg }}>

            {array.slice(0, pageSize).map((pois: any, index: any) => {

              let textShort = ''

              if (pois.description.length !== 0) {
                textShort = `${pois.description.substring(0, 480)}`
                textShort = textShort.slice(0, textShort.lastIndexOf("&"))
              }

              return (

                <div className={classes.containerPois} key={pois.id}>

                  <div className={classes.containerPoisTitleContainer} style={{ backgroundColor: pois.extras._colour['value'] }}>
                    <div className={classes.containerPoisTitle} style={{ backgroundColor: pois.extras._colour['value'] }}>
                      <span className={classes.containerPoisNumber}>{pois.extras._route_code['value']} </span>
                      <span className={i18n.language == 'en' ? [classes.containerPoisText, classes.paddingTop].join(' ') : classes.containerPoisText}>
                        <div>{pois.name}</div>
                        <hr />
                        {i18n.language !== 'en' &&
                          <div>{pois.extras._short_description_all['value']}</div>
                        }
                       
                      </span>
                    </div>
                  </div>

                  {/* <div className={classes.containerPois2} style={{ backgroundColor: ColorsContainer2[index] }}>
                  https://10x-programming.com/hex-values-to-decimal-and-percentage-converter
                  */}
                  <div className={classes.containerPois2} style={{ backgroundColor: `${pois.extras._colour['value']}38` }}>
                    <Fragment>

                      <PoisComponent
                        id={pois.id}
                        image={pois.main_image !== '' ? pois.main_image : '../img/defaultPoisImage.jpg'}
                        title={pois.title}
                        rate={pois.rate}
                        text={textShort}  
                        municipality={pois.municipality}
                        category={pois.category}

                      />

                    </Fragment>
                  </div>

                </div>
              )
            })}

          </Col>
        </Row>
      </BrowserView>

    
      <MobileView>
        <Row justify="center">
          <Col xs={{ span: xs }} sm={{ span: xs }} md={{ span: lg }} lg={{ span: lg }}>

            <Carousel {...settingsCarouselEvents}>

              {array.slice(0, pageSize).map((pois: any, index: any) => {

                let textShort = ''

                if (pois.description.length !== 0) {
                  textShort = `${pois.description.substring(0, 480)}`
                  textShort = textShort.slice(0, textShort.lastIndexOf("&"))
                }

                return (

                  <div className={classes.containerPois} key={pois.id}>

                    <div className={classes.containerPoisTitleContainer} style={{ backgroundColor: pois.extras._colour['value'] }}>

                      <div className={classes.containerPoisTitle} style={{ backgroundColor: pois.extras._colour['value'] }}>
                        <span className={classes.containerPoisNumber}>{pois.extras._route_code['value']}</span>
                        <span className={classes.containerPoisText}>
                        <div>{pois.extras._super_short_description['value']}</div>
                        <hr />
                        <div>{pois.extras._short_description_all['value']}</div>
                      </span>
                      </div>
                    </div>

                    <div className={classes.containerPois2} style={{ backgroundColor: `${pois.extras._colour['value']}38` }}>
                      <Fragment>

                        <PoisComponent
                          id={pois.id}
                          image={pois.main_image !== '' ? pois.main_image : '../img/defaultPoisImage.jpg'}
                          title={pois.title}
                          rate={pois.rate}
                          text={textShort}
                          municipality={pois.municipality}
                          category={pois.category}
                        />

                      </Fragment>
                    </div>

                  </div>
                )
              })}

            </Carousel>

          </Col>
        </Row>
        <br /><br /><br /><br /><br />
      </MobileView>

    </>
  )
}

export default ViewPointsComponent