
import React, {useState, useEffect} from "react"
import { Row, Col, Button, Spin, Drawer, Space  } from 'antd'
import classes from './styles.module.css'
import { useTranslation } from "react-i18next"
import i18n from '../../translation'
import { useAreas } from '../../hooks/useAreas'
import {  Link } from 'react-router-dom'
import HelmetComponent from '../../components/Helmet'
import Spinner from '../../components/PageComponents/Spinner'
import TitleAndSubtitle from '../../components/PageComponents/TitleAndSubtitle'
import CheckBoxComponent from './checkBoxGroup'
import ViewRoutesComponent from './viewRoutes'
import LiefletComponent from './Lieflet'
import { CloseOutlined } from '@ant-design/icons'
import MapSvg from '../../img/svg/map'
import { NodeIndexOutlined } from  "@ant-design/icons"
import GlobusSvg from '../../img/svg/globus'

import type { RootState } from '../../redux/store'
import { useSelector } from 'react-redux'

import {RoutesTypeData} from './routesType'

import { useRoutes } from '../../hooks/useRoute'
import utils from '../../utils'


import ViewPointsComponent from '../Home/components/viewPointsSecond'
import Events from '../Home/components/Events'

const Routes: React.FC = () => {

  const { t } = useTranslation()

  const { isLoading, RoutesData, refetchs } =  useRoutes()

  const { areas } = useAreas()


  const ReduxRoutes: any[] = useSelector((state: RootState) => state.Routes.Routes)
  const ReduxMunicipalities: any[] = useSelector((state: RootState) => state.Routes.Municipality)

  const [municipalities, setMunicipalities] = useState<any[]>([]);

  const [routes, setRoutesData] = useState<any[]>([]);
  const [polyline, setPolyline] = useState<[number, number][][]>([]);
  const [pois, setPois] = useState<[number, number][][]>([]);

  const [setPoisesMarkerExploration, PoisesMarkerExploration ] = useState<any[]>([]);

  const [showMap, setShowMap] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);



  
  useEffect(() => {
    // refetchs()
    setMunicipalities(areas)
    // if(RoutesData)
    setRoutesData(RoutesData || [])
  
  }, [ RoutesData, ReduxRoutes, ReduxMunicipalities])


  useEffect(() => {

    let polyline: any[] = []
    let addPois: any[] = []
    let pois: any[] = []

    if (RoutesData) {
      let result = utils.filterRoutesArray(RoutesData, ReduxRoutes, ReduxMunicipalities)

      for (let i = 0; i < result.length; i++) {

        pois.push([])
        polyline.push([])
        addPois.push([])

        result[i].route_coordinates.forEach((popyl: any, j: any, row: any): void => {
          if (j + 1 === row.length) {
            addPois[i].push({ id: result[i].pois.length + 1, latitude: parseFloat(popyl.latitude), longitude: parseFloat(popyl.longitude), name: 'Finish' })
          }
          polyline[i].push([parseFloat(popyl.latitude), parseFloat(popyl.longitude)])
        })

        result[i].pois.forEach((poi: any): void => {
  
          pois[i].push({
            main_id: result[i].id,
            id: poi.id,
            latitude: parseFloat(poi.latitude),
            longitude: parseFloat(poi.longitude),
            image: poi.main_image,
            title: poi.name,
            description: poi.portal_main_info,
            category: poi.categories[0].name,
            typesOfRoute: poi.extras._point_codes_per_route['value'],
            colour: result[i].extras._colour['value']
          })
        })

      }

      result.length >= 1 ? setRoutesData(result) : setRoutesData([])
      setPolyline(polyline)
      setPois(pois)
    }

  },[ RoutesData, ReduxRoutes, ReduxMunicipalities ])

  
  useEffect(() => {
    refetchs()
  }, [i18n.language])


  
  // if ( !RoutesData ) {
  //   return (<Spinner />)
  // }



  if (isLoading || routes.length < 1) {
    
    return (<Spinner />)
    
  }


  return (
    <>
      <HelmetComponent
        title={t('routes_title') as string}
        descrition={t('routes_description') as string}
        keywords={t('routes_keywords') as string}
      />

     <div style={{backgroundColor: '#f7f2ed'}}>
      
      <TitleAndSubtitle
        title={t('Εξερεύνησε τις θεματικές διαδρομές')}
        subTitle={t('Βρες τα σημεία που σε ενδιαφέρουν στο Χάρτη')}
      />

      
      <div className={classes.MainContainer}>
        <div className={classes.checkBoxesContainerPoint}>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <div className={classes.btnFiltersAndMap}>    
                <div className={[classes.Title, classes.Title2].join(" ")}>{t('Θεματικές διαδρομές') }</div>   
                <div className={classes.btn}>
                  <Button type="primary" icon={<MapSvg width={21} height={21} fill="#fff" />} size='large' className={classes.btnMap} onClick={() => setShowMap(!showMap)}>&nbsp;&nbsp; {!showMap ? t('Εμφάνιση Χάρτη') : t('Aπόκρυψη Χάρτη')} </Button>
                </div>
              </div>
            </Col>
            {showMap &&
              <Col xs={24} sm={24} md={24} lg={24}>
                <LiefletComponent
                  pois={pois}
                  polyline={polyline}
                  iconImg={<NodeIndexOutlined width={25} height={25} color="#275674" />}
                  setPoisesMarkerExploration={setPoisesMarkerExploration}
                  routes={routes}
                
                />
              </Col>
            }
          </Row>
        </div>
      </div>
      
      <div className={classes.MainContainer}>
        <Row justify="center">

          <Col xs={24} sm={24} md={24} lg={24}>
            <Row justify="center">
                <Col xs={24} sm={24} md={24} lg={24}>
                  <div style={{padding: '0px 20px'}}>
                <ViewPointsComponent
                  lg={24}
                  xs={24}
                  paginationNumber={1}
                  array={routes}
                  total={routes.length}
                  isAddOrDelete={true}
                  setPoisesMarkerExploration={1}
                  onClickAddRemove={() => ''} />
 </div>
              </Col>
            </Row>
          </Col>

        </Row>
      </div>
      </div>
      <Events color='grey'/>

    </>
  )
}

export default Routes
