
import React, {useState, useEffect} from "react"
import { Row, Col, Button, Spin, Rate, message, Popconfirm, Tooltip } from 'antd'
import classes from './styles.module.css'
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next"
import { usePois } from '../../../hooks/useExploration'
import { useParams, useNavigate } from 'react-router-dom'
import { HeartOutlined, ExclamationOutlined , ArrowLeftOutlined, ArrowRightOutlined} from '@ant-design/icons'


import { useUserFavoriteAddOrRemovePoint } from '../../../hooks/useUser'
import { useAuth } from "../../../utils/auth"

import GoToPoiSvg from '../../../img/svg/goToPoi'
import Events from '../../Home/components/Events'

import HelmetComponent from '../../../components/Helmet'
import LiefletComponent from './Lieflet'
import SliderComponent from './Slider'
import Slider3DComponent from './Slider3d'
import SliderVideoComponent from './SliderVideo'
import TitleAndSubtitleAndBtn from '../../../components/PageComponents/TitleAndSubtitleAndBtn'

import PlayComponent from './Play'
import ThreePoisesComponent from './ThreePoises'


import i18n from '../../../translation'


import ImageSvg from '../../../img/svg/ImageSVG'
import ImageThreeSvg from '../../../img/svg/ImageThreeSVG'
import ImageVideoSvg from '../../../img/svg/ImageVideoSVG'
import {ChangeMunicipality} from '../../../components/Box/ChangeMunicipality'
import TypesOfRoute from '../../../components/TypesOfRoute'



import { useSelector } from 'react-redux'
import type { RootState } from '../../../redux/store'
// import { SetRoutesArray } from '../../../redux/mapPage/routesArray'
import { useGetArrayPoisFromRoute } from '../../../hooks/useRoute'


const ExplorationView: React.FC = () => {

  const { t } = useTranslation()
  const auth = useAuth()

  const location = useLocation()
  let urlElelement = (location.pathname.split('/'))


  const { id } = useParams()
  const { isLoading, isFetching, PoisData, refe } = usePois(id || '')
  const { RouteData } =  useGetArrayPoisFromRoute(id || '')


  
  const RoutesArr: any[] = useSelector((state: RootState) => state.RoutesArr.Routes)
  const RoutesArr2: any[] = useSelector((state: RootState) => state.RoutesArr2.Routes2)



  const navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage()

  const [pois, setPois] = useState<any>({})
  const [poisId, setPoisId] = useState<string>('')
  const { refetch } = useUserFavoriteAddOrRemovePoint(auth.userData.id, poisId, 1)
  const [showImages, setShowImages] = useState<boolean>(true)
  const [showImages360, setShowImages360] = useState<boolean>(false)
  const [showVideo, setShowVideo] = useState<boolean>(false)


  const [itemIndex, setItemIndex] = useState<number>(0)



  useEffect(() => {



  }, [RoutesArr]);
  


  const changeView = (view1: boolean, view2: boolean, view3: boolean) => {

    setShowImages(view1)
    setShowImages360(view2)
    setShowVideo(view3)
  }

  const onClickAdd = (poisId: string) => {

    setPoisId(poisId)

    messageApi.open({
          type: 'success',
          content: t('Σημείο Ενδιαφέροντος προστέθηκε με επιτυχία'),
          style: {
            marginTop: '20vh'
          },
        })
  }

  useEffect(() => {
    if (poisId !== '')
    refetch()
 }, [poisId]);


  useEffect(() => {
    if(PoisData)
      setPois(PoisData)
  }, [PoisData])

  
  useEffect(() => {
    refe()
  }, [i18n.language])




  useEffect(() => {
  
    if( RoutesArr !== undefined && RoutesArr[0] &&  RoutesArr[0]?.length !== 0)
    setItemIndex(RoutesArr[0].indexOf(urlElelement[2]))
  }, [urlElelement[2], RoutesArr])


  useEffect(() => {
    if( itemIndex === 0 || itemIndex === RoutesArr[0].length -1 )
     window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [itemIndex])


  const showInMapClicked = () => {
    window.open(`https://www.google.com/maps/dir//${pois.lat},+${pois.lng}/@${pois.lat},${pois.lng},13z/data=!4m6!4m5!1m0!1m3!2m2!1d22.3801386!2d38.3825321`)
  };


  // console.log('RoutesArr[0]', RoutesArr[0])
  // console.log('urlElelement[2]', urlElelement[2])
  

  if ( (isLoading || Object.keys(pois).length < 0 ) ) {
    return (
      <Row justify="center" >
        <Col span={12} offset={6}>
          <div className={classes.loadingSpinner}> <Spin size="large" /> {t('Παρακαλώ περιμένετε')} ... </div>
        </Col>
      </Row>
    )
  }

  return (
    <>
      {contextHolder}

      <HelmetComponent
        title={pois.title}
        descrition={pois.title+', ' + ChangeMunicipality(t(pois.municipality))}
        keywords='Εξερεύνηση'
      />

      {isFetching && <div className={classes.allScreen}>
          <div className={classes.loader}></div>
      </div>}
      
      <div className={classes.titleGrey2}>
          {pois.title}
      </div>
      
     
        <div className={classes.containerRate}>
          <Row justify="center">
            <Col span={14}>
              <div className={classes.rate}>
                <Rate disabled allowHalf value={pois.rating} />
            
                {pois.title &&
                  <div style={{padding: '26px 0px 20px 0px'}}>
                    <TypesOfRoute codesPerRoute={pois.typesOfRoute} />
                  </div>
                }  
              </div>
            </Col>
          </Row>
        </div>
      
      
      {pois.image && pois.image.length !== 0 && showImages &&
        <Row justify="center">  
          <Col xs={16} sm={16} md={{ span: 16 }} lg={14}>
            <div className={classes.helper}>
              <img src={pois.image} alt="Image from Pois" />
            </div>
          </Col>
        </Row> 
      }


      <br/><br/>

      <Row justify="center">

        <Col xs={16} sm={14} md={24} lg={24}>
          <div className={classes.PointFavoriteBtnCenter}>
            <Button
              size='large'
              icon={<GoToPoiSvg width={18} height={18} fill='#63584F' />}
              className={classes.PointFavoriteBtn}
              onClick={showInMapClicked}
            >
              &nbsp;{t('Μετάβαση στο σημείο')}
            </Button>
          </div>  
        </Col>


      </Row>

      
      <PlayComponent title={pois.title } mp3={pois.mp3 } />
      
      <Row justify="center">
        <Col xs={{ span: 22 }} sm={{ span: 22 }} md={{ span: 14 }} lg={{ span: 14 }} >
          <hr className={classes.hr2} />
        </Col>
      </Row>

      <Row justify="center">
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 14 }} lg={{ span: 14 }} >
          <div className={classes.description}>
            <br/>
            <div className={classes.eventTitle}>{t('Περιγραφή')}</div>

            <div className={classes.eventDescription} dangerouslySetInnerHTML={{ __html: pois.description }} />

            <div className={classes.eventPhoto}>{t('Πολυμεσικό Υλικό')}</div>

            {pois.images && pois.images.length !== 0 && showImages &&
              
              <SliderComponent images={pois.images} />
            }

            {pois.images3d && pois.images3d.length !== 0 && showImages360 &&
             
             <Slider3DComponent images={pois.images3d} />
            }

            {pois.video && pois.video.length !== 0 && showVideo &&
             
             <SliderVideoComponent url={pois.video[0].url} />
            }

            <div className={classes.demoImg}>

              {pois.images && pois.images.length !== 0 && pois.images3d.length !== 0 &&
                <Tooltip placement="topLeft" title={t('Προβολή φωτογραφιών')} >
                  
                  <span onClick={() => changeView(true, false, false)}>
                    <img src={pois.images[0].url} alt='preview image' className={classes.demoImgImg} />
                    <span><ImageSvg /></span>
                    <span className={classes.textDemo}>Image</span>
                  </span>
                  
                </Tooltip>
              }  

              {pois.images3d && pois.images3d.length !== 0 &&
                <Tooltip placement="topLeft" title={t('Εικονική περιήγηση')} >
                  <span onClick={() => changeView(false, true, false)}>
                    <img src={pois.images3d[0].url} alt='preview image' className={classes.demoImgImg} />
                    <span><ImageThreeSvg /></span>
                    <span className={classes.textDemo}>360&#176;</span>
                  </span>
                </Tooltip>
              }  

              {pois.video && pois.video.length !== 0 &&
                <Tooltip placement="topLeft" title={t('Αναπαραγωγή Βίντεο')} >
                  <span onClick={() => changeView(false, false, true)}>
                    <img src={pois.images[0].url} alt='preview Video' className={classes.demoImgImg} />
                    <span><ImageVideoSvg /></span>
                    <span className={classes.textDemo}>Video</span>
                  </span>
                </Tooltip>
              } 
              
            </div>
         </div>
        </Col>
      </Row>
      

      <br />
      
        {pois.lat &&
          <Row justify="center">
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 14 }} lg={{ span: 14 }} >
            <div className={classes.description}>
           
              <div className={classes.eventTitle}>{t('Χάρτης')}</div> 
              
              <LiefletComponent
                image={pois.image}
                title={pois.title}
                latitude={pois.lat}
                longitude={pois.lng}
              />
            </div> 
            </Col>
          </Row>
        }
     

      
    
    {(RoutesArr !== undefined && RoutesArr[0] && RoutesArr[0]?.length !== 0) &&
        
      <>
        <Row justify="center">
          <Col span={24}>
            <div className={classes.titleGrey}>
              {t('Συνέχισε τη διαδρομή σου')}
            </div>
          </Col>
        </Row>

        <Row justify="center">
          <Col xs={{ span: 22 }} lg={{ span: 22 }}>
            <div className={classes.subTitle}>
              {t('Εξερεύνησε τα υπόλοιπα σημεία')}
            </div>
          </Col>
        </Row>


        <div style={{ marginBottom: 100 }}>
          <Row justify="center">
           
            <Col span={11} xs={24} sm={24} md={11} lg={11}>
              <div className={classes.sectionTitle}>
                <Button
                  disabled={itemIndex == 0}
                  size='large'
                  icon={<ArrowLeftOutlined />}
                  onClick={ () => navigate(`/exploration/${RoutesArr[0][itemIndex - 1]}`) }
                  className={classes.ReturnBtn}>&nbsp;&nbsp;{t('Προηγούμενο σημείο')}&nbsp; ({RoutesArr2[0][itemIndex - 1]})
                </Button>

              </div>
            </Col>

            <div className={classes.space}></div>
            <Col span={11} xs={24} sm={24} md={11} lg={11}>
              <div className={classes.sectionTitle}>
                <Button
                  size='large'
                  className={classes.HomeBtn}
                  disabled={itemIndex === RoutesArr[0].length-1}
                  onClick={() => navigate(`/exploration/${RoutesArr[0][itemIndex + 1]}`)}>

                  {t('Επόμενο σημείο')} &nbsp;({ RoutesArr2[0]?.length !== 0 && RoutesArr2[0][itemIndex + 1]})&nbsp;&nbsp;<ArrowRightOutlined />
                </Button>
              </div>
            </Col>
          </Row>
        </div>


      </>
    }

    <br/><br/>

    <div>
      <Row justify="center" className={classes.DestinationContainer}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} >
          <Events color='grey'/>
        </Col>
      </Row>
    </div>

    </>
  )
}

export default ExplorationView
