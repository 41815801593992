import ky from 'ky'
import {API,APIRoutes,  Header, HeaderRoutes} from './configuration'
import i18n from '../../src/translation'

export const axiosExplorations = {


  async getCategories() {
    const data: any = await ky.get(`${API}/categoriesTree?lang=${i18n.language == 'gr' ? 'el' : i18n.language }`, {headers: Header}).json()
    return data.success
  },

  async getPois(id: string) {
    const data: any = await ky.get(`${APIRoutes}/poi?id=${id}&fetch_files=1&lang=${i18n.language == 'gr' ? 'el' : i18n.language }`, {headers: HeaderRoutes}).json()
    return data.success;
  },

  async getPoises() {
    const data: any = await ky.get(`${API}/pois?fetch_files=1&lang=${i18n.language == 'gr' ? 'el' : i18n.language }`, {headers: Header}).json()
    return data.success;
  },


  async getPoisOmeka(id: string) {
    const data: any = await ky.get(`https://chaniaroutes.mitos.cityguideplatform.com/api/poi?fetch_files=1&omeka_id=${id}`, {headers: HeaderRoutes}).json()
    return data.success;
  }



}